/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import ProfileScreen from '../screens/ProfileScreen';
import {
  ProfileStackParamList,
} from '../types';
import { useTheme } from '../constants/Theme';

const ProfileStack = createNativeStackNavigator<ProfileStackParamList>();

const ProfileTabStack = () => {
  const theme = useTheme();

  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name='Profile'
        component={ProfileScreen}
        options={{
          title: 'Profile',
          headerStyle: {
            backgroundColor: theme.colors.headerBackground,
          },
          headerShadowVisible: false,
          headerTransparent: false,
          headerTintColor: theme.colors.headerIconColor,
          headerTitleStyle: {
            color: theme.colors.headerColor,
          },
        }}
      />
    </ProfileStack.Navigator>
  );
};

export default ProfileTabStack;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateStringOrTimestamp: any;
  DateTime: string;
  ObjectId: string;
};

export type AcceptCompetitionInviteResponse = Competition | ForbiddenError | NotFoundError;

export type AcceptUserInviteResponse = ForbiddenError | NotFoundError | User;

export type AuthenticationError = Error & {
  readonly __typename: 'AuthenticationError';
  readonly message: Scalars['String'];
};

export enum BristolScale {
  averageStool = 'averageStool',
  diarrhea = 'diarrhea',
  sausageShapedButLumpy = 'sausageShapedButLumpy',
  sausageWithCracks = 'sausageWithCracks',
  separateHardLumpsLikeNuts = 'separateHardLumpsLikeNuts',
  softBlobs = 'softBlobs',
  watery = 'watery'
}

export type CategoryAppetiteChangeSample = ICategorySample & {
  readonly __typename: 'CategoryAppetiteChangeSample';
  readonly _id: Scalars['ObjectId'];
  readonly appetiteChange: CategoryValueAppetiteChange;
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
};

export type CategoryBristolStoolScaleSample = ICategorySample & {
  readonly __typename: 'CategoryBristolStoolScaleSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly bristolScale: BristolScale;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
};

export type CategoryCervicalMucusQualitySample = ICategorySample & {
  readonly __typename: 'CategoryCervicalMucusQualitySample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly cervicalMucusQuality: CategoryValueCervicalMucusQuality;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
};

export type CategoryContraceptiveSample = ICategorySample & {
  readonly __typename: 'CategoryContraceptiveSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly contraceptive: ContraceptiveType;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
};

export type CategoryMenstrualFlowSample = ICategorySample & {
  readonly __typename: 'CategoryMenstrualFlowSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly menstrualFlow: CategoryValueMenstrualFlow;
  readonly to: Scalars['DateTime'];
};

export type CategoryOvulationTestResult = ICategorySample & {
  readonly __typename: 'CategoryOvulationTestResult';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly ovulationTestResult: CategoryValueOvulationTestResult;
  readonly to: Scalars['DateTime'];
};

export type CategoryPresenceSample = ICategorySample & {
  readonly __typename: 'CategoryPresenceSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly presence: CategoryValuePresence;
  readonly to: Scalars['DateTime'];
  readonly type: CategoryTypePresence;
};

export type CategorySample = CategoryAppetiteChangeSample | CategoryBristolStoolScaleSample | CategoryCervicalMucusQualitySample | CategoryMenstrualFlowSample | CategoryOvulationTestResult | CategoryPresenceSample | CategorySeveritySample | CategorySleepAnalysisSample | CategoryUnspecifiedSample;

export type CategorySeveritySample = ICategorySample & {
  readonly __typename: 'CategorySeveritySample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly severity: CategoryValueSeverity;
  readonly severityType: CategoryTypeIdentifierSeverity;
  readonly to: Scalars['DateTime'];
};

export type CategorySleepAnalysisSample = ICategorySample & {
  readonly __typename: 'CategorySleepAnalysisSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly sleepAnalysis: CategoryValueSleepAnalysis;
  readonly to: Scalars['DateTime'];
};

export enum CategoryTypeIdentifierSeverity {
  abdominalCramps = 'abdominalCramps',
  acne = 'acne',
  bladderIncontinence = 'bladderIncontinence',
  bloating = 'bloating',
  breastPain = 'breastPain',
  chestTightnessOrPain = 'chestTightnessOrPain',
  chills = 'chills',
  constipation = 'constipation',
  coughing = 'coughing',
  diarrhea = 'diarrhea',
  dizziness = 'dizziness',
  drySkin = 'drySkin',
  fainting = 'fainting',
  fatigue = 'fatigue',
  fever = 'fever',
  generalizedBodyAche = 'generalizedBodyAche',
  hairLoss = 'hairLoss',
  headache = 'headache',
  heartburn = 'heartburn',
  hotFlashes = 'hotFlashes',
  lossOfSmell = 'lossOfSmell',
  lossOfTaste = 'lossOfTaste',
  lowerBackPain = 'lowerBackPain',
  memoryLapse = 'memoryLapse',
  moodChanges = 'moodChanges',
  nausea = 'nausea',
  nightSweats = 'nightSweats',
  pelvicPain = 'pelvicPain',
  rapidPoundingOrFlutteringHeartbeat = 'rapidPoundingOrFlutteringHeartbeat',
  runnyNose = 'runnyNose',
  shortnessOfBreath = 'shortnessOfBreath',
  sinusCongestion = 'sinusCongestion',
  skippedHeartbeat = 'skippedHeartbeat',
  soreThroat = 'soreThroat',
  vaginalDryness = 'vaginalDryness',
  vomiting = 'vomiting',
  wheezing = 'wheezing'
}

export type CategoryTypeInput =
  { readonly appetiteChange: CategoryValueAppetiteChange; readonly bristolScale?: never; readonly cervicalMucusQuality?: never; readonly menstrualFlow?: never; readonly ovulationTestResult?: never; readonly sleepAnalysis?: never; }
  |  { readonly appetiteChange?: never; readonly bristolScale: BristolScale; readonly cervicalMucusQuality?: never; readonly menstrualFlow?: never; readonly ovulationTestResult?: never; readonly sleepAnalysis?: never; }
  |  { readonly appetiteChange?: never; readonly bristolScale?: never; readonly cervicalMucusQuality: CategoryValueCervicalMucusQuality; readonly menstrualFlow?: never; readonly ovulationTestResult?: never; readonly sleepAnalysis?: never; }
  |  { readonly appetiteChange?: never; readonly bristolScale?: never; readonly cervicalMucusQuality?: never; readonly menstrualFlow: CategoryValueMenstrualFlow; readonly ovulationTestResult?: never; readonly sleepAnalysis?: never; }
  |  { readonly appetiteChange?: never; readonly bristolScale?: never; readonly cervicalMucusQuality?: never; readonly menstrualFlow?: never; readonly ovulationTestResult: CategoryValueOvulationTestResult; readonly sleepAnalysis?: never; }
  |  { readonly appetiteChange?: never; readonly bristolScale?: never; readonly cervicalMucusQuality?: never; readonly menstrualFlow?: never; readonly ovulationTestResult?: never; readonly sleepAnalysis: CategoryValueSleepAnalysis; };

export enum CategoryTypePresence {
  appetiteChanges = 'appetiteChanges',
  sleepChanges = 'sleepChanges'
}

export enum CategoryTypeUnspecified {
  highHeartRateEvent = 'highHeartRateEvent',
  intermenstrualBleeding = 'intermenstrualBleeding',
  mindfulSession = 'mindfulSession',
  sexualActivity = 'sexualActivity'
}

export type CategoryUnspecifiedSample = ICategorySample & {
  readonly __typename: 'CategoryUnspecifiedSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
  readonly value: CategoryTypeUnspecified;
};

export enum CategoryValueAppetiteChange {
  decreased = 'decreased',
  increased = 'increased',
  noChange = 'noChange',
  unspecified = 'unspecified'
}

export enum CategoryValueCervicalMucusQuality {
  creamy = 'creamy',
  dry = 'dry',
  eggWhite = 'eggWhite',
  sticky = 'sticky',
  watery = 'watery'
}

export enum CategoryValueMenstrualFlow {
  heavy = 'heavy',
  light = 'light',
  medium = 'medium',
  none = 'none',
  unspecified = 'unspecified'
}

export enum CategoryValueOvulationTestResult {
  estrogenSurge = 'estrogenSurge',
  indeterminate = 'indeterminate',
  luteinizingHormoneSurge = 'luteinizingHormoneSurge',
  negative = 'negative'
}

export enum CategoryValuePresence {
  notPresent = 'notPresent',
  present = 'present'
}

export enum CategoryValueSeverity {
  mild = 'mild',
  moderate = 'moderate',
  notPresent = 'notPresent',
  severe = 'severe',
  unspecified = 'unspecified'
}

export enum CategoryValueSleepAnalysis {
  asleepCore = 'asleepCore',
  asleepDeep = 'asleepDeep',
  asleepREM = 'asleepREM',
  asleepUnspecified = 'asleepUnspecified',
  awake = 'awake',
  inBed = 'inBed'
}

export type CodeNotValidError = Error & {
  readonly __typename: 'CodeNotValidError';
  readonly message: Scalars['String'];
};

export type Competition = {
  readonly __typename: 'Competition';
  readonly _id: Scalars['ObjectId'];
  readonly competitionState: CompetitionState;
  readonly competitorIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly competitors: ReadonlyArray<RestrictedUser>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy?: Maybe<RestrictedUser>;
  readonly createdById: Scalars['ObjectId'];
  readonly endDate: Scalars['DateTime'];
  readonly leaderboard: ReadonlyArray<LeaderboardEntry>;
  readonly modifiedAt: Scalars['DateTime'];
  readonly modifiedBy?: Maybe<RestrictedUser>;
  readonly modifiedById: Scalars['ObjectId'];
  readonly name: Scalars['String'];
  readonly quantityType: QuantityType;
  readonly startDate: Scalars['DateTime'];
  readonly workoutTypes: ReadonlyArray<WorkoutActivityType>;
};

export type CompetitionByIdResponse = Competition | NotFoundError;

export type CompetitionInvitationResponse = ForbiddenError | InvitationResponseSuccess | NotFoundError;

export enum CompetitionState {
  FINISHED = 'FINISHED',
  FUTURE = 'FUTURE',
  STARTED = 'STARTED'
}

export enum ContraceptiveType {
  implant = 'implant',
  injection = 'injection',
  intrauterineDevice = 'intrauterineDevice',
  intravaginalRing = 'intravaginalRing',
  oral = 'oral',
  patch = 'patch',
  unspecified = 'unspecified'
}

export enum CorrelationTimeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR'
}

export type EmailNotValidError = Error & {
  readonly __typename: 'EmailNotValidError';
  readonly message: Scalars['String'];
};

export type Error = {
  readonly message: Scalars['String'];
};

export type Food = {
  readonly __typename: 'Food';
  readonly _id: Scalars['ObjectId'];
  readonly allNutrientsPer100g: ReadonlyArray<NutrientQuantity>;
  readonly createdAt: Scalars['DateTime'];
  readonly foodsWithProportions: ReadonlyArray<FoodWithProportion>;
  readonly image?: Maybe<Scalars['String']>;
  readonly modifiedAt: Scalars['DateTime'];
  readonly nutrientsPer100g: ReadonlyArray<NutrientQuantity>;
  readonly standardServingSize?: Maybe<Scalars['Float']>;
  readonly title: Scalars['String'];
};

export type FoodWithProportion = {
  readonly __typename: 'FoodWithProportion';
  readonly food: Food;
  readonly foodId: Scalars['ObjectId'];
  readonly proportion: Scalars['Float'];
};

export type FoodWithProportionInput = {
  readonly foodId: Scalars['ObjectId'];
  readonly proportion: Scalars['Float'];
};

export type ForbiddenError = Error & {
  readonly __typename: 'ForbiddenError';
  readonly message: Scalars['String'];
};

export type GlucoseQuantitySample = IQuantitySample & {
  readonly __typename: 'GlucoseQuantitySample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly unitWithValue: UnitWithValue;
};


export type GlucoseQuantitySampleUnitWithValueArgs = {
  unit?: InputMaybe<GlucoseUnit>;
};

export enum GlucoseUnit {
  MILLIGRAM_PER_DECILITER = 'MILLIGRAM_PER_DECILITER',
  MILLIMOL_PER_LITER = 'MILLIMOL_PER_LITER',
  MILLIMOL_PER_MOL = 'MILLIMOL_PER_MOL'
}

export type GlucoseUnitWithValue = {
  readonly __typename: 'GlucoseUnitWithValue';
  readonly unit: GlucoseUnit;
  readonly value: Scalars['Float'];
};

export type ICategorySample = {
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly from: Scalars['DateTime'];
  readonly to: Scalars['DateTime'];
};

export type ICommonSampleProps = {
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly modifiedAt: Scalars['DateTime'];
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
};

export type IQuantitySample = {
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly unitWithValue: UnitWithValue;
};

export type IUser = {
  readonly _id: Scalars['ObjectId'];
  readonly avatarUrl: Scalars['String'];
  readonly displayName: Scalars['String'];
};

export type InvitationResponseSuccess = {
  readonly __typename: 'InvitationResponseSuccess';
  readonly invitationToken: Scalars['String'];
  readonly invitationUrl: Scalars['String'];
};

export type LeaderboardEntry = {
  readonly __typename: 'LeaderboardEntry';
  readonly score: StatWithValue;
  readonly user: RestrictedUser;
};

export type LibreMonitor = {
  readonly __typename: 'LibreMonitor';
  readonly _id: Scalars['ObjectId'];
  readonly email: Scalars['String'];
};

export enum Locale {
  EN = 'EN',
  SV = 'SV'
}

export type Location = {
  readonly __typename: 'Location';
  readonly accuracy?: Maybe<Scalars['Float']>;
  readonly altitude?: Maybe<Scalars['Float']>;
  readonly altitudeAccuracy?: Maybe<Scalars['Float']>;
  readonly heading?: Maybe<Scalars['Float']>;
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly speed?: Maybe<Scalars['Float']>;
  readonly timestamp: Scalars['DateTime'];
};

export type LocationInput = {
  readonly accuracy?: InputMaybe<Scalars['Float']>;
  readonly altitude?: InputMaybe<Scalars['Float']>;
  readonly altitudeAccuracy?: InputMaybe<Scalars['Float']>;
  readonly heading?: InputMaybe<Scalars['Float']>;
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly speed?: InputMaybe<Scalars['Float']>;
};

export type LoginConfirmResponse = CodeNotValidError | EmailNotValidError | LoginConfirmSuccessfulResponse | LoginFailedError;

export type LoginConfirmSuccessfulResponse = {
  readonly __typename: 'LoginConfirmSuccessfulResponse';
  readonly accessToken: Scalars['String'];
};

export type LoginFailedError = Error & {
  readonly __typename: 'LoginFailedError';
  readonly message: Scalars['String'];
};

export type LoginRequestResponse = EmailNotValidError | LoginRequestSuccessResponse;

export type LoginRequestSuccessResponse = {
  readonly __typename: 'LoginRequestSuccessResponse';
  readonly success: Scalars['Boolean'];
};

export type MealSample = ICommonSampleProps & {
  readonly __typename: 'MealSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly derivedQuantityIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly endDate: Scalars['DateTime'];
  readonly foodsWithProportions: ReadonlyArray<FoodWithProportion>;
  readonly image?: Maybe<Scalars['String']>;
  readonly mealType: MealType;
  readonly modifiedAt: Scalars['DateTime'];
  readonly parentMealId: Scalars['ObjectId'];
  readonly quantities: ReadonlyArray<QuantitySample>;
  readonly quantitiesAfterMeal: ReadonlyArray<QuantitySample>;
  readonly quantityIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly statAfterMeal: Scalars['Float'];
  readonly title?: Maybe<Scalars['String']>;
  readonly userHash: Scalars['String'];
  readonly weightInGrams?: Maybe<Scalars['Float']>;
};


export type MealSampleQuantitiesAfterMealArgs = {
  durationInMinutes: Scalars['Int'];
  offsetInMinutes?: InputMaybe<Scalars['Int']>;
  quantityType: QuantityType;
};


export type MealSampleStatAfterMealArgs = {
  durationInMinutes: Scalars['Int'];
  offsetInMinutes?: InputMaybe<Scalars['Int']>;
  quantityType: QuantityType;
  statType: QuantityStat;
};

export type MealSampleUpsert = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly foodsWithProportions?: InputMaybe<ReadonlyArray<FoodWithProportionInput>>;
  readonly mealType?: InputMaybe<MealType>;
  readonly parentMealId?: InputMaybe<Scalars['ObjectId']>;
  readonly quantities?: InputMaybe<ReadonlyArray<QuantitySampleUpsert>>;
  readonly sourceType?: InputMaybe<SourceType>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly timestamp?: InputMaybe<Scalars['DateTime']>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly weightInGrams?: InputMaybe<Scalars['Float']>;
};

export enum MealType {
  BREAKFAST = 'BREAKFAST',
  DINNER = 'DINNER',
  LUNCH = 'LUNCH',
  SNACK = 'SNACK',
  UNKOWN = 'UNKOWN'
}

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly acceptCompetitionInvite: AcceptCompetitionInviteResponse;
  readonly acceptFollowerInvite: AcceptUserInviteResponse;
  readonly generateCompetitionInvite: CompetitionInvitationResponse;
  readonly generateFollowerInvite: InvitationResponseSuccess;
  readonly loginConfirm: LoginConfirmResponse;
  readonly loginRequest: LoginRequestResponse;
  readonly saveLocation: User;
  readonly subscribeToLibre: LibreMonitor;
  readonly updateDedupedSamples: Scalars['Boolean'];
  readonly upsertCategorySample: CategorySample;
  readonly upsertCategorySamples: UpsertedResult;
  readonly upsertCompetition: Competition;
  readonly upsertCustomExercise: WorkoutExercise;
  readonly upsertMeals: UpsertedResult;
  readonly upsertPresenceType: CategoryPresenceSample;
  readonly upsertQuantitySample: QuantitySample;
  readonly upsertQuantitySamples: UpsertedResult;
  readonly upsertSet: WorkoutSet;
  readonly upsertSeverityType: CategorySeveritySample;
  readonly upsertWorkout: WorkoutSample;
  readonly upsertWorkoutSamples: UpsertedResult;
};


export type MutationAcceptCompetitionInviteArgs = {
  inviteToken: Scalars['String'];
};


export type MutationAcceptFollowerInviteArgs = {
  inviteToken: Scalars['String'];
};


export type MutationGenerateCompetitionInviteArgs = {
  competitionId: Scalars['ObjectId'];
};


export type MutationLoginConfirmArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginRequestArgs = {
  email: Scalars['String'];
};


export type MutationSaveLocationArgs = {
  appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  sourceType?: InputMaybe<SourceType>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateDedupedSamplesArgs = {
  fromDate: Scalars['DateStringOrTimestamp'];
  quantityType: QuantityType;
  toDate: Scalars['DateStringOrTimestamp'];
};


export type MutationUpsertCategorySampleArgs = {
  sample: UpsertCategorySample;
};


export type MutationUpsertCategorySamplesArgs = {
  samples: ReadonlyArray<UpsertCategorySample>;
};


export type MutationUpsertCompetitionArgs = {
  competition: UpsertCompetition;
};


export type MutationUpsertCustomExerciseArgs = {
  appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  exerciseId?: InputMaybe<Scalars['ObjectId']>;
  name: Scalars['String'];
  sourceType?: InputMaybe<SourceType>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpsertMealsArgs = {
  samples: ReadonlyArray<MealSampleUpsert>;
};


export type MutationUpsertPresenceTypeArgs = {
  appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  presence: CategoryValuePresence;
  presenceType: CategoryTypePresence;
  sourceType?: InputMaybe<SourceType>;
  to?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpsertQuantitySampleArgs = {
  quantitySample: QuantitySampleUpsert;
};


export type MutationUpsertQuantitySamplesArgs = {
  quantityType: QuantityType;
  samples: ReadonlyArray<QuantitySamplesUpsertValue>;
  sourceType?: InputMaybe<SourceType>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  unit: UnitInternal;
};


export type MutationUpsertSetArgs = {
  set: WorkoutSetInput;
};


export type MutationUpsertSeverityTypeArgs = {
  appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  severity: CategoryValueSeverity;
  severityType: CategoryTypeIdentifierSeverity;
  sourceType?: InputMaybe<SourceType>;
  to?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpsertWorkoutArgs = {
  workout: WorkoutSampleUpsert;
};


export type MutationUpsertWorkoutSamplesArgs = {
  samples: ReadonlyArray<WorkoutSampleUpsert>;
};

export type NotFoundError = Error & {
  readonly __typename: 'NotFoundError';
  readonly message: Scalars['String'];
};

export type NutrientQuantity = {
  readonly __typename: 'NutrientQuantity';
  readonly nutrient: QuantityType;
  readonly quantity: Scalars['Float'];
  readonly unit: UnitInternal;
};

export type PageInfo = {
  readonly __typename: 'PageInfo';
  readonly endCursor?: Maybe<Scalars['ID']>;
  readonly hasNextPage: Scalars['Boolean'];
};

export type QuantitySample = ICommonSampleProps & {
  readonly __typename: 'QuantitySample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
  readonly quantityType: QuantityType;
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly unit: UnitInternal;
  readonly userHash: Scalars['String'];
  readonly value: Scalars['Float'];
};


export type QuantitySampleValueArgs = {
  asUnit?: InputMaybe<Unit>;
};

export type QuantitySampleConnection = {
  readonly __typename: 'QuantitySampleConnection';
  readonly edges: ReadonlyArray<QuantitySampleEdge>;
  readonly nodes: ReadonlyArray<QuantitySample>;
  readonly pageInfo: PageInfo;
};

export type QuantitySampleData = {
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
};

export type QuantitySampleDataImplemented = {
  readonly __typename: 'QuantitySampleDataImplemented';
  readonly commonData: QuantitySampleData;
  readonly unitWithValue: GlucoseUnitWithValue;
};


export type QuantitySampleDataImplementedUnitWithValueArgs = {
  unit?: InputMaybe<GlucoseUnit>;
};

export type QuantitySampleDeduped = {
  readonly __typename: 'QuantitySampleDeduped';
  readonly candidateSampleIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly candidateSamples: ReadonlyArray<QuantitySample>;
  readonly contributingSampleIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly contributingSamples: ReadonlyArray<QuantitySample>;
  readonly endDate: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly quantityType: QuantityType;
  readonly sourceTypes: ReadonlyArray<SourceType>;
  readonly startDate: Scalars['DateTime'];
  readonly unit: UnitInternal;
  readonly value: Scalars['Float'];
};


export type QuantitySampleDedupedValueArgs = {
  asUnit?: InputMaybe<Unit>;
};

export type QuantitySampleEdge = {
  readonly __typename: 'QuantitySampleEdge';
  readonly cursor: Scalars['ID'];
  readonly node: QuantitySample;
};

export type QuantitySampleUpsert = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly quantityType: QuantityType;
  readonly sourceType?: InputMaybe<SourceType>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly timestamp?: InputMaybe<Scalars['DateTime']>;
  readonly unit: UnitInternal;
  readonly value: Scalars['Float'];
};

export type QuantitySamplesUpsertValue = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly value: Scalars['Float'];
};

export type QuantitySort = {
  readonly sortBy: QuantitySortBy;
  readonly sortDirection: SortDirection;
};

export enum QuantitySortBy {
  createdAt = 'createdAt',
  endDate = 'endDate',
  modifiedAt = 'modifiedAt',
  startDate = 'startDate',
  value = 'value'
}

export enum QuantityStat {
  AVG = 'AVG',
  COUNT = 'COUNT',
  MAX = 'MAX',
  MIN = 'MIN',
  STD_DEV_POP = 'STD_DEV_POP',
  STD_DEV_SAMPLE = 'STD_DEV_SAMPLE',
  SUM = 'SUM'
}

export enum QuantityType {
  SUBJECTIVE_CARB_AMOUNT = 'SUBJECTIVE_CARB_AMOUNT',
  SUBJECTIVE_ENERGY_LEVEL = 'SUBJECTIVE_ENERGY_LEVEL',
  SUBJECTIVE_MEAL_SIZE = 'SUBJECTIVE_MEAL_SIZE',
  SUBJECTIVE_MOOD_LEVEL = 'SUBJECTIVE_MOOD_LEVEL',
  SUBJECTIVE_SLEEP_QUALITY = 'SUBJECTIVE_SLEEP_QUALITY',
  SUBJECTIVE_STRESS_LEVEL = 'SUBJECTIVE_STRESS_LEVEL',
  SUBJECTIVE_WORKOUT_PERFORMANCE = 'SUBJECTIVE_WORKOUT_PERFORMANCE',
  activeEnergyBurned = 'activeEnergyBurned',
  appleExerciseTime = 'appleExerciseTime',
  appleMoveTime = 'appleMoveTime',
  appleStandTime = 'appleStandTime',
  appleWalkingSteadiness = 'appleWalkingSteadiness',
  basalBodyTemperature = 'basalBodyTemperature',
  basalEnergyBurned = 'basalEnergyBurned',
  bloodAlcoholContent = 'bloodAlcoholContent',
  bloodGlucose = 'bloodGlucose',
  bloodPressureDiastolic = 'bloodPressureDiastolic',
  bloodPressureSystolic = 'bloodPressureSystolic',
  bodyFatPercentage = 'bodyFatPercentage',
  bodyMass = 'bodyMass',
  bodyMassIndex = 'bodyMassIndex',
  bodyTemperature = 'bodyTemperature',
  dietaryBiotin = 'dietaryBiotin',
  dietaryCaffeine = 'dietaryCaffeine',
  dietaryCalcium = 'dietaryCalcium',
  dietaryCarbohydrates = 'dietaryCarbohydrates',
  dietaryChloride = 'dietaryChloride',
  dietaryCholesterol = 'dietaryCholesterol',
  dietaryChromium = 'dietaryChromium',
  dietaryCopper = 'dietaryCopper',
  dietaryEnergyConsumed = 'dietaryEnergyConsumed',
  dietaryFatMonounsaturated = 'dietaryFatMonounsaturated',
  dietaryFatPolyunsaturated = 'dietaryFatPolyunsaturated',
  dietaryFatSaturated = 'dietaryFatSaturated',
  dietaryFatTotal = 'dietaryFatTotal',
  dietaryFiber = 'dietaryFiber',
  dietaryFolate = 'dietaryFolate',
  dietaryIodine = 'dietaryIodine',
  dietaryIron = 'dietaryIron',
  dietaryMagnesium = 'dietaryMagnesium',
  dietaryManganese = 'dietaryManganese',
  dietaryMolybdenum = 'dietaryMolybdenum',
  dietaryNiacin = 'dietaryNiacin',
  dietaryPantothenicAcid = 'dietaryPantothenicAcid',
  dietaryPhosphorus = 'dietaryPhosphorus',
  dietaryPotassium = 'dietaryPotassium',
  dietaryProtein = 'dietaryProtein',
  dietaryRiboflavin = 'dietaryRiboflavin',
  dietarySelenium = 'dietarySelenium',
  dietarySodium = 'dietarySodium',
  dietarySugar = 'dietarySugar',
  dietaryThiamin = 'dietaryThiamin',
  dietaryVitaminA = 'dietaryVitaminA',
  dietaryVitaminB6 = 'dietaryVitaminB6',
  dietaryVitaminB12 = 'dietaryVitaminB12',
  dietaryVitaminC = 'dietaryVitaminC',
  dietaryVitaminD = 'dietaryVitaminD',
  dietaryVitaminE = 'dietaryVitaminE',
  dietaryVitaminK = 'dietaryVitaminK',
  dietaryWater = 'dietaryWater',
  dietaryZinc = 'dietaryZinc',
  distanceCycling = 'distanceCycling',
  distanceDownhillSnowSports = 'distanceDownhillSnowSports',
  distanceSwimming = 'distanceSwimming',
  distanceWalkingRunning = 'distanceWalkingRunning',
  distanceWheelchair = 'distanceWheelchair',
  electrodermalActivity = 'electrodermalActivity',
  environmentalAudioExposure = 'environmentalAudioExposure',
  flightsClimbed = 'flightsClimbed',
  forcedExpiratoryVolume1 = 'forcedExpiratoryVolume1',
  forcedVitalCapacity = 'forcedVitalCapacity',
  headphoneAudioExposure = 'headphoneAudioExposure',
  heartRate = 'heartRate',
  heartRateVariabilitySDNN = 'heartRateVariabilitySDNN',
  height = 'height',
  inhalerUsage = 'inhalerUsage',
  insulinDeliveryBasal = 'insulinDeliveryBasal',
  insulinDeliveryBolus = 'insulinDeliveryBolus',
  leanBodyMass = 'leanBodyMass',
  nikeFuel = 'nikeFuel',
  numberOfAlcoholicBeverages = 'numberOfAlcoholicBeverages',
  numberOfTimesFallen = 'numberOfTimesFallen',
  oxygenSaturation = 'oxygenSaturation',
  peakExpiratoryFlowRate = 'peakExpiratoryFlowRate',
  peripheralPerfusionIndex = 'peripheralPerfusionIndex',
  pushCount = 'pushCount',
  respiratoryRate = 'respiratoryRate',
  restingHeartRate = 'restingHeartRate',
  sixMinuteWalkTestDistance = 'sixMinuteWalkTestDistance',
  stairAscentSpeed = 'stairAscentSpeed',
  stairDescentSpeed = 'stairDescentSpeed',
  stepCount = 'stepCount',
  swimmingStrokeCount = 'swimmingStrokeCount',
  timeAsleep = 'timeAsleep',
  uvExposure = 'uvExposure',
  vo2Max = 'vo2Max',
  waistCircumference = 'waistCircumference',
  walkingAsymmetryPercentage = 'walkingAsymmetryPercentage',
  walkingDoubleSupportPercentage = 'walkingDoubleSupportPercentage',
  walkingHeartRateAverage = 'walkingHeartRateAverage',
  walkingSpeed = 'walkingSpeed',
  walkingStepLength = 'walkingStepLength'
}

export type QuantityTypeMetadata = {
  readonly __typename: 'QuantityTypeMetadata';
  readonly lastDataTime?: Maybe<Scalars['DateTime']>;
  readonly quantityType: QuantityType;
};

export type Query = {
  readonly __typename: 'Query';
  readonly competitionById: CompetitionByIdResponse;
  readonly me: User;
  readonly timestamp: Scalars['DateTime'];
  readonly userById: UserByIdResponse;
};


export type QueryCompetitionByIdArgs = {
  competitionId: Scalars['ObjectId'];
};


export type QueryUserByIdArgs = {
  userId: Scalars['ObjectId'];
};

export enum ResistanceType {
  BARBELL = 'BARBELL',
  BODY_WEIGHT = 'BODY_WEIGHT',
  CABLE = 'CABLE',
  DUMBBELL = 'DUMBBELL',
  KETTLEBELL = 'KETTLEBELL',
  MACHINE = 'MACHINE',
  UNKNOWN = 'UNKNOWN'
}

export type RestrictedUser = IUser & {
  readonly __typename: 'RestrictedUser';
  readonly _id: Scalars['ObjectId'];
  readonly avatarUrl: Scalars['String'];
  readonly displayName: Scalars['String'];
};

export type SampleSort = {
  readonly sortBy: SampleSortBy;
  readonly sortDirection: SortDirection;
};

export enum SampleSortBy {
  createdAt = 'createdAt',
  endDate = 'endDate',
  modifiedAt = 'modifiedAt',
  startDate = 'startDate',
  value = 'value'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SourceType {
  APPLE_WATCH = 'APPLE_WATCH',
  EXTERNAL = 'EXTERNAL',
  GAINS = 'GAINS',
  HEALTHKIT = 'HEALTHKIT',
  INTERNAL = 'INTERNAL',
  IPHONE = 'IPHONE',
  LIBRELINKUP = 'LIBRELINKUP'
}

export type StatWithValue = {
  readonly __typename: 'StatWithValue';
  readonly unit: Unit;
  readonly value: Scalars['Float'];
};

export type StatWithValueGrouped = {
  readonly __typename: 'StatWithValueGrouped';
  readonly timeBucket: Scalars['Int'];
  readonly unit: Unit;
  readonly value: Scalars['Float'];
  readonly valueInUnit: Scalars['Float'];
};


export type StatWithValueGroupedValueInUnitArgs = {
  unit: Unit;
};

export type StatWithValueGroupedWithLookback = {
  readonly __typename: 'StatWithValueGroupedWithLookback';
  readonly lookback: Scalars['Int'];
  readonly timeBucket: Scalars['Int'];
  readonly unit: Unit;
  readonly value: Scalars['Float'];
  readonly valueInUnit: Scalars['Float'];
};


export type StatWithValueGroupedWithLookbackValueInUnitArgs = {
  unit: Unit;
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly tick: Scalars['DateTime'];
};

export enum TimeUnit {
  DAY_OF_MONTH = 'DAY_OF_MONTH',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  DAY_OF_YEAR = 'DAY_OF_YEAR',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export enum Unit {
  CELSIUS = 'CELSIUS',
  CENTILITER = 'CENTILITER',
  CENTIMETER = 'CENTIMETER',
  COUNT = 'COUNT',
  COUNT_PER_MINUTE = 'COUNT_PER_MINUTE',
  DAY = 'DAY',
  FAHRENHEIT = 'FAHRENHEIT',
  GRAM = 'GRAM',
  HOUR = 'HOUR',
  INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',
  KILOCALORIE = 'KILOCALORIE',
  KILOGRAM = 'KILOGRAM',
  KILOJOULE = 'KILOJOULE',
  KILOMETER = 'KILOMETER',
  LITER = 'LITER',
  METER = 'METER',
  MICROLITER = 'MICROLITER',
  MILLIGRAM_PER_DECILITER = 'MILLIGRAM_PER_DECILITER',
  MILLILITER = 'MILLILITER',
  MILLILITRE_PER_KILOGRAM_PER_MINUTE = 'MILLILITRE_PER_KILOGRAM_PER_MINUTE',
  MILLIMETER = 'MILLIMETER',
  MILLIMOL_PER_LITER = 'MILLIMOL_PER_LITER',
  MILLIMOL_PER_MOL = 'MILLIMOL_PER_MOL',
  MILLISECOND = 'MILLISECOND',
  MINUTE = 'MINUTE',
  PERCENT = 'PERCENT',
  SECOND = 'SECOND'
}

export enum UnitInternal {
  CELSIUS = 'CELSIUS',
  COUNT = 'COUNT',
  COUNT_PER_MINUTE = 'COUNT_PER_MINUTE',
  DECIBEL_SOUND_PRESSURE_LEVEL = 'DECIBEL_SOUND_PRESSURE_LEVEL',
  GRAM = 'GRAM',
  INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',
  KILOCALORIE = 'KILOCALORIE',
  METER_PER_SECOND = 'METER_PER_SECOND',
  MILLILITER = 'MILLILITER',
  MILLILITRE_PER_KILOGRAM_PER_MINUTE = 'MILLILITRE_PER_KILOGRAM_PER_MINUTE',
  MILLIMETER = 'MILLIMETER',
  MILLIMOL_PER_LITER = 'MILLIMOL_PER_LITER',
  MILLIMOL_PER_MOL = 'MILLIMOL_PER_MOL',
  MILLISECOND = 'MILLISECOND',
  PERCENT = 'PERCENT'
}

export type UnitWithValue = {
  readonly __typename: 'UnitWithValue';
  readonly unit: Unit;
  readonly value: Scalars['Float'];
};

export type UpsertCategorySample = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  readonly from?: InputMaybe<Scalars['DateTime']>;
  readonly sourceType?: InputMaybe<SourceType>;
  readonly to?: InputMaybe<Scalars['DateTime']>;
  readonly value: CategoryTypeInput;
};

export type UpsertCompetition = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly endDate: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly quantityType: QuantityType;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly timestamp?: InputMaybe<Scalars['DateTime']>;
  readonly workoutTypes: ReadonlyArray<WorkoutActivityType>;
};

export enum UpsertOperationType {
  INSERTED = 'INSERTED',
  UPSERTED = 'UPSERTED'
}

export type UpsertedItem = {
  readonly __typename: 'UpsertedItem';
  readonly _id: Scalars['ObjectId'];
  readonly result: UpsertOperationType;
};

export type UpsertedResult = {
  readonly __typename: 'UpsertedResult';
  readonly insertedCount: Scalars['Int'];
  readonly items: ReadonlyArray<UpsertedItem>;
  readonly modifiedCount: Scalars['Int'];
  readonly upsertedCount: Scalars['Int'];
};

export type User = IUser & {
  readonly __typename: 'User';
  readonly _id: Scalars['ObjectId'];
  readonly availableQuantitySampleTypes: ReadonlyArray<QuantityType>;
  readonly avatarUrl: Scalars['String'];
  readonly categorySamples: ReadonlyArray<CategorySample>;
  readonly chartImageUrl: Scalars['String'];
  readonly competitions: ReadonlyArray<Competition>;
  readonly correlationsBeforeSleep: ReadonlyArray<StatWithValueGroupedWithLookback>;
  readonly correlationsFromSeverity: ReadonlyArray<StatWithValueGrouped>;
  readonly displayName: Scalars['String'];
  readonly email: Scalars['String'];
  readonly exercises: ReadonlyArray<WorkoutExercise>;
  readonly followerIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly followers: ReadonlyArray<RestrictedUser>;
  readonly following: ReadonlyArray<User>;
  readonly isConfirmed: Scalars['Boolean'];
  readonly lastActiveAt: Scalars['DateTime'];
  readonly locations: ReadonlyArray<Location>;
  readonly mealSamples: ReadonlyArray<MealSample>;
  readonly quantitySamples: ReadonlyArray<QuantitySample>;
  readonly quantitySamplesDeduped: ReadonlyArray<QuantitySampleDeduped>;
  readonly quantitySamplesGlucose: ReadonlyArray<GlucoseQuantitySample>;
  readonly quantitySamplesPaged: QuantitySampleConnection;
  readonly quantitySamplesStream: ReadonlyArray<QuantitySample>;
  /** Default time span is last week */
  readonly quantityStat: StatWithValue;
  readonly quantityStatPerTimeUnit: ReadonlyArray<StatWithValueGrouped>;
  readonly quantityTypeMetadata: ReadonlyArray<QuantityTypeMetadata>;
  readonly userSettings: UserSettings;
  readonly workoutById?: Maybe<WorkoutSample>;
  readonly workoutSamples: ReadonlyArray<WorkoutSample>;
};


export type UserCategorySamplesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserChartImageUrlArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  quantityType: QuantityType;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserCorrelationsBeforeSleepArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  quantityType: QuantityType;
  statistic: QuantityStat;
  timeUnit: CorrelationTimeUnit;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserCorrelationsFromSeverityArgs = {
  categoryType: CategoryTypeIdentifierSeverity;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  lookbackTime: Scalars['Int'];
  quantityType: QuantityType;
  statistic: QuantityStat;
  timeUnit: CorrelationTimeUnit;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserLocationsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirection>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserMealSamplesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ReadonlyArray<SampleSort>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserQuantitySamplesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  quantityType: QuantityType;
  sortBy?: InputMaybe<ReadonlyArray<QuantitySort>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserQuantitySamplesDedupedArgs = {
  fromDate?: InputMaybe<Scalars['DateStringOrTimestamp']>;
  quantityType: QuantityType;
  sortBy?: InputMaybe<ReadonlyArray<QuantitySort>>;
  toDate?: InputMaybe<Scalars['DateStringOrTimestamp']>;
};


export type UserQuantitySamplesGlucoseArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ReadonlyArray<QuantitySort>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserQuantitySamplesPagedArgs = {
  after?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  quantityType: QuantityType;
  sortBy?: InputMaybe<ReadonlyArray<QuantitySort>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type UserQuantitySamplesStreamArgs = {
  fromDate: Scalars['DateTime'];
  quantityType: QuantityType;
};


export type UserQuantityStatArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  quantityType: QuantityType;
  skipFill?: InputMaybe<Scalars['Boolean']>;
  statistic: QuantityStat;
  toDate?: InputMaybe<Scalars['DateTime']>;
  unit?: InputMaybe<Unit>;
};


export type UserQuantityStatPerTimeUnitArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  quantityType: QuantityType;
  skipFill?: InputMaybe<Scalars['Boolean']>;
  statistic: QuantityStat;
  timeUnit: TimeUnit;
  toDate?: InputMaybe<Scalars['DateTime']>;
  unit?: InputMaybe<Unit>;
};


export type UserQuantityTypeMetadataArgs = {
  sources?: InputMaybe<ReadonlyArray<SourceType>>;
};


export type UserWorkoutByIdArgs = {
  workoutId: Scalars['ObjectId'];
};


export type UserWorkoutSamplesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ReadonlyArray<SampleSort>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type UserByIdResponse = NotFoundError | RestrictedUser | User;

export enum UserInputErrorMessageCode {
  INVALID_EMAIL = 'INVALID_EMAIL',
  LOGIN_CODE_SHOULD_BE_6_CHARACTERS = 'LOGIN_CODE_SHOULD_BE_6_CHARACTERS',
  UNSPECIFIED = 'UNSPECIFIED'
}

export type UserSettings = {
  readonly __typename: 'UserSettings';
  readonly locale: Locale;
  readonly prefers24HourClock: Scalars['Boolean'];
};

export enum WorkoutActivityType {
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  ARCHERY = 'ARCHERY',
  AUSTRALIAN_FOOTBALL = 'AUSTRALIAN_FOOTBALL',
  BADMINTON = 'BADMINTON',
  BARRE = 'BARRE',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  BOWLING = 'BOWLING',
  BOXING = 'BOXING',
  CLIMBING = 'CLIMBING',
  CORE_TRAINING = 'CORE_TRAINING',
  CRICKET = 'CRICKET',
  CROSS_COUNTRY_SKIING = 'CROSS_COUNTRY_SKIING',
  CROSS_TRAINING = 'CROSS_TRAINING',
  CURLING = 'CURLING',
  CYCLING = 'CYCLING',
  DANCE = 'DANCE',
  DANCE_INSPIRED_TRAINING = 'DANCE_INSPIRED_TRAINING',
  DISC_SPORTS = 'DISC_SPORTS',
  DOWNHILL_SKIING = 'DOWNHILL_SKIING',
  ELLIPTICAL = 'ELLIPTICAL',
  EQUESTRIAN_SPORTS = 'EQUESTRIAN_SPORTS',
  FENCING = 'FENCING',
  FISHING = 'FISHING',
  FITNESS_GAMING = 'FITNESS_GAMING',
  FLEXIBILITY = 'FLEXIBILITY',
  FUNCTIONAL_STRENGTH_TRAINING = 'FUNCTIONAL_STRENGTH_TRAINING',
  GOLF = 'GOLF',
  GYMNASTICS = 'GYMNASTICS',
  HANDBALL = 'HANDBALL',
  HAND_CYCLING = 'HAND_CYCLING',
  HIGH_INTENSITY_INTERVAL_TRAINING = 'HIGH_INTENSITY_INTERVAL_TRAINING',
  HIKING = 'HIKING',
  HOCKEY = 'HOCKEY',
  HUNTING = 'HUNTING',
  JUMP_ROPE = 'JUMP_ROPE',
  KICKBOXING = 'KICKBOXING',
  LACROSSE = 'LACROSSE',
  MARTIAL_ARTS = 'MARTIAL_ARTS',
  MIND_AND_BODY = 'MIND_AND_BODY',
  MIXED_CARDIO = 'MIXED_CARDIO',
  MIXED_METABOLIC_CARDIO_TRAINING = 'MIXED_METABOLIC_CARDIO_TRAINING',
  OTHER = 'OTHER',
  PADDLE_SPORTS = 'PADDLE_SPORTS',
  PILATES = 'PILATES',
  PLAY = 'PLAY',
  PREPARATION_AND_RECOVERY = 'PREPARATION_AND_RECOVERY',
  RACQUETBALL = 'RACQUETBALL',
  ROWING = 'ROWING',
  RUGBY = 'RUGBY',
  RUNNING = 'RUNNING',
  SAILING = 'SAILING',
  SKATING_SPORTS = 'SKATING_SPORTS',
  SNOWBOARDING = 'SNOWBOARDING',
  SNOW_SPORTS = 'SNOW_SPORTS',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  SQUASH = 'SQUASH',
  STAIRS = 'STAIRS',
  STAIR_CLIMBING = 'STAIR_CLIMBING',
  STEP_TRAINING = 'STEP_TRAINING',
  SURFING_SPORTS = 'SURFING_SPORTS',
  SWIMMING = 'SWIMMING',
  TABLE_TENNIS = 'TABLE_TENNIS',
  TAI_CHI = 'TAI_CHI',
  TENNIS = 'TENNIS',
  TRACK_AND_FIELD = 'TRACK_AND_FIELD',
  TRADITIONAL_STRENGTH_TRAINING = 'TRADITIONAL_STRENGTH_TRAINING',
  VOLLEYBALL = 'VOLLEYBALL',
  WALKING = 'WALKING',
  WATER_FITNESS = 'WATER_FITNESS',
  WATER_POLO = 'WATER_POLO',
  WATER_SPORTS = 'WATER_SPORTS',
  WHEELCHAIR_RUN_PACE = 'WHEELCHAIR_RUN_PACE',
  WHEELCHAIR_WALK_PACE = 'WHEELCHAIR_WALK_PACE',
  WRESTLING = 'WRESTLING',
  YOGA = 'YOGA'
}

export type WorkoutExercise = {
  readonly __typename: 'WorkoutExercise';
  readonly _id: Scalars['ObjectId'];
  readonly createdAt: Scalars['DateTime'];
  readonly exerciseType: WorkoutExerciseType;
  readonly exerciseTypeCustomId?: Maybe<Scalars['String']>;
  readonly lastSet?: Maybe<WorkoutSet>;
  readonly modifiedAt: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly sets: ReadonlyArray<WorkoutSet>;
  readonly sourceType?: Maybe<SourceType>;
  readonly userHash?: Maybe<Scalars['String']>;
};


export type WorkoutExerciseSetsArgs = {
  after?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum WorkoutExerciseType {
  BACK_EXTENSION = 'BACK_EXTENSION',
  BRIDGE_HIP_SINGLE_LEG = 'BRIDGE_HIP_SINGLE_LEG',
  BURPEE = 'BURPEE',
  CALF_PRESS = 'CALF_PRESS',
  CALF_RAISE = 'CALF_RAISE',
  CALF_RAISE_SEATED = 'CALF_RAISE_SEATED',
  CALF_RAISE_STANDING = 'CALF_RAISE_STANDING',
  CHINUP = 'CHINUP',
  CLEAN = 'CLEAN',
  CLEAN_HANG = 'CLEAN_HANG',
  CLEAN_HANG_POWER = 'CLEAN_HANG_POWER',
  CLEAN_JERK = 'CLEAN_JERK',
  CLEAN_POWER = 'CLEAN_POWER',
  CRUNCH = 'CRUNCH',
  CRUNCH_TWISTING = 'CRUNCH_TWISTING',
  CURL_BICEP = 'CURL_BICEP',
  CUSTOM = 'CUSTOM',
  DEADLIFT = 'DEADLIFT',
  DEADLIFT_RDL = 'DEADLIFT_RDL',
  DEADLIFT_SINGLE_LEG = 'DEADLIFT_SINGLE_LEG',
  DEADLIFT_STRAIGHT_LEG = 'DEADLIFT_STRAIGHT_LEG',
  DIP = 'DIP',
  DIP_CHEST = 'DIP_CHEST',
  DIP_TRICEPS = 'DIP_TRICEPS',
  FLY = 'FLY',
  GOOD_MORNING = 'GOOD_MORNING',
  HIP_EXTENSION = 'HIP_EXTENSION',
  HIP_RAISE = 'HIP_RAISE',
  HIP_THRUST = 'HIP_THRUST',
  JUMPING_JACK = 'JUMPING_JACK',
  LEG_CURL = 'LEG_CURL',
  LEG_EXTENSION = 'LEG_EXTENSION',
  LEG_PRESS = 'LEG_PRESS',
  LEG_RAISE = 'LEG_RAISE',
  LUNGE = 'LUNGE',
  LUNGE_REAR = 'LUNGE_REAR',
  LUNGE_SIDE = 'LUNGE_SIDE',
  PLANK = 'PLANK',
  PLANK_SIDE = 'PLANK_SIDE',
  PRESS_BENCH = 'PRESS_BENCH',
  PRESS_BENCH_CLOSE_GRIP = 'PRESS_BENCH_CLOSE_GRIP',
  PRESS_BENCH_DECLINE = 'PRESS_BENCH_DECLINE',
  PRESS_BENCH_INCLINE = 'PRESS_BENCH_INCLINE',
  PRESS_JM = 'PRESS_JM',
  PRESS_PIKE = 'PRESS_PIKE',
  PRESS_SHOULDER = 'PRESS_SHOULDER',
  PRESS_SHOULDER_ARNOLD = 'PRESS_SHOULDER_ARNOLD',
  PRESS_SHOULDER_MILITARY = 'PRESS_SHOULDER_MILITARY',
  PULLDOWN = 'PULLDOWN',
  PULLOVER = 'PULLOVER',
  PULLUP = 'PULLUP',
  PUSHUP = 'PUSHUP',
  PUSHUP_CLOSE_GRIP = 'PUSHUP_CLOSE_GRIP',
  PUSHUP_PIKE = 'PUSHUP_PIKE',
  RAISE_FRONT = 'RAISE_FRONT',
  RAISE_LATERAL = 'RAISE_LATERAL',
  RAISE_LATERAL_REAR = 'RAISE_LATERAL_REAR',
  ROW = 'ROW',
  ROW_HIGH = 'ROW_HIGH',
  ROW_UPRIGHT = 'ROW_UPRIGHT',
  RUN_HIGH_KNEE = 'RUN_HIGH_KNEE',
  RUSSIAN_TWIST = 'RUSSIAN_TWIST',
  SHRUG = 'SHRUG',
  SITUP = 'SITUP',
  SITUP_TWISTING = 'SITUP_TWISTING',
  SQUAT = 'SQUAT',
  STEP_UP = 'STEP_UP',
  SWING = 'SWING',
  THRUSTER = 'THRUSTER',
  TRICEPS_EXTENSION = 'TRICEPS_EXTENSION',
  VUPS = 'VUPS',
  WALL_SIT = 'WALL_SIT'
}

export type WorkoutSample = ICommonSampleProps & {
  readonly __typename: 'WorkoutSample';
  readonly _id: Scalars['ObjectId'];
  readonly appleHealthKitUUID?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly exerciseIds: ReadonlyArray<Scalars['ObjectId']>;
  readonly exercises: ReadonlyArray<WorkoutExercise>;
  readonly modifiedAt: Scalars['DateTime'];
  readonly quantities: ReadonlyArray<QuantitySample>;
  readonly quantitiesAfterWorkout: ReadonlyArray<QuantitySample>;
  readonly sets: ReadonlyArray<WorkoutSet>;
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly stat: Scalars['Float'];
  readonly statAfterWorkout: Scalars['Float'];
  readonly userHash: Scalars['String'];
  readonly workoutActivityType: WorkoutActivityType;
};


export type WorkoutSampleQuantitiesArgs = {
  quantityType: QuantityType;
};


export type WorkoutSampleQuantitiesAfterWorkoutArgs = {
  durationInMinutes: Scalars['Int'];
  quantityType: QuantityType;
};


export type WorkoutSampleStatArgs = {
  quantityType: QuantityType;
  statType: QuantityStat;
};


export type WorkoutSampleStatAfterWorkoutArgs = {
  durationInMinutes: Scalars['Int'];
  offsetInMinutes?: InputMaybe<Scalars['Int']>;
  quantityType: QuantityType;
  statType: QuantityStat;
};

export type WorkoutSampleUpsert = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly appleHealthKitUUID?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly exerciseIds?: InputMaybe<ReadonlyArray<Scalars['ObjectId']>>;
  readonly sourceType?: InputMaybe<SourceType>;
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  readonly timestamp?: InputMaybe<Scalars['DateTime']>;
  readonly workoutActivityType: WorkoutActivityType;
};

export type WorkoutSet = {
  readonly __typename: 'WorkoutSet';
  readonly _id: Scalars['ObjectId'];
  readonly endDate?: Maybe<Scalars['DateTime']>;
  readonly exercise?: Maybe<WorkoutExercise>;
  readonly exerciseId: Scalars['ObjectId'];
  readonly repititions?: Maybe<Scalars['Int']>;
  readonly resistanceType?: Maybe<ResistanceType>;
  readonly resistanceUnit?: Maybe<UnitInternal>;
  readonly resistanceValue?: Maybe<Scalars['Float']>;
  readonly startDate: Scalars['DateTime'];
  readonly userHash: Scalars['String'];
  readonly weight?: Maybe<Scalars['Float']>;
  readonly workout?: Maybe<WorkoutSample>;
  readonly workoutId: Scalars['ObjectId'];
};

export type WorkoutSetInput = {
  readonly _id?: InputMaybe<Scalars['ObjectId']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']>;
  readonly exerciseId: Scalars['ObjectId'];
  readonly repititions?: InputMaybe<Scalars['Int']>;
  readonly resistanceType?: InputMaybe<ResistanceType>;
  readonly resistanceUnit?: InputMaybe<UnitInternal>;
  readonly resistanceValue?: InputMaybe<Scalars['Float']>;
  readonly sourceType: SourceType;
  readonly startDate: Scalars['DateTime'];
  readonly weight?: InputMaybe<Scalars['Float']>;
  readonly workoutId: Scalars['ObjectId'];
};

import { StyleSheet } from 'react-native';

import { useTheme } from '../constants/Theme';
import { Text, TextProps } from './Themed';

const styles = StyleSheet.create({
  titleHuge: {
    fontWeight: '800',
    fontSize: 36,
  },
  titleXl: {
    fontWeight: '500',
    fontSize: 40,
  },
  titleLg: {
    fontWeight: '500',
    fontSize: 32,
  },
  titleMd: {
    fontWeight: '500',
    fontSize: 24,
  },
  titleBase: {
    fontWeight: '500',
    fontSize: 16,
  },
  titleSm: {
    fontWeight: '500',
    fontSize: 12,
  },
  titleXs: {
    fontWeight: '500',
    fontSize: 8,
  },
  textXl: {
    fontWeight: '400',
    fontSize: 40,
  },
  textLg: {
    fontWeight: '400',
    fontSize: 32,
  },
  textMd: {
    fontWeight: '400',
    fontSize: 24,
  },
  textBase: {
    fontWeight: '400',
    fontSize: 16,
  },
  textSm: {
    fontWeight: '400',
    fontSize: 12,
  },
  textXs: {
    fontWeight: '400',
    fontSize: 8,
  },
});

type TextSize = 'huge' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export function StyledTitle({ style, size, ...props }: TextProps & { readonly size?: TextSize }) {
  const theme = useTheme();
  if (size === 'huge') {
    return (
      <Text {...props} style={[styles.titleHuge, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'xl') {
    return (
      <Text {...props} style={[styles.titleXl, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'lg') {
    return (
      <Text {...props} style={[styles.titleLg, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'md') {
    return (
      <Text {...props} style={[styles.titleMd, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'sm') {
    return (
      <Text {...props} style={[styles.titleSm, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'xs') {
    return (
      <Text {...props} style={[styles.titleXs, { color: theme.colors.title }, style]} />
    );
  }
  return <Text {...props} style={[styles.titleBase, { color: theme.colors.title }, style]} />;
}

export function StyledText({ style, size, ...props }: TextProps & { readonly size?: TextSize }) {
  const theme = useTheme();

  if (size === 'xl') {
    return (
      <Text {...props} style={[styles.textXl, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'lg') {
    return (
      <Text {...props} style={[styles.textLg, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'md') {
    return (
      <Text {...props} style={[styles.textMd, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'sm') {
    return (
      <Text {...props} style={[styles.textSm, { color: theme.colors.title }, style]} />
    );
  }
  if (size === 'xs') {
    return (
      <Text {...props} style={[styles.textXs, { color: theme.colors.title }, style]} />
    );
  }
  return <Text {...props} style={[styles.textBase, { color: theme.colors.text }, style]} />;
}

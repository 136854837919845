import { Platform, Share } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import useAlert from '@kingstinct/react/hooks/useAlert';

import { useInviteToCompetitionMutation } from '../clients/operations.generated';

const useInviteToCompetition = (competitionId: string) => {
  const inviteToCompetitionLazy = useInviteToCompetitionLazy();

  const fn = async () => inviteToCompetitionLazy(competitionId);

  return fn;
};

export const useInviteToCompetitionLazy = () => {
  const [, inviteToCompetition] = useInviteToCompetitionMutation();
  const presentAlert = useAlert();

  const fn = async (competitionId: string) => {
    const { data } = await inviteToCompetition({ competitionId });
    if (data?.generateCompetitionInvite.__typename === 'InvitationResponseSuccess') {
      const url = data.generateCompetitionInvite.invitationUrl;
      try {
        await Share.share(Platform.OS === 'ios' ? {
          message: 'Join my competition!',
          url,
        } : {
          message: url,
          title: 'Join my competition!',
        });
      } catch (e) {
        await Clipboard.setStringAsync(url);
        await presentAlert('Invitation link copied to clipboard, send it to friends to join your competition!');
      }
    }
  };

  return fn;
};

export default useInviteToCompetition;

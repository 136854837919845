/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import { AuthContext } from '../contexts/AuthContext';
import NewCompetitionScreen from '../screens/NewCompetitionScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import {
  RootStackParamList,
} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import NotLoggedInNavigator from './AuthFlow';
import BottomTabs from './BottomTabs';

export default function Navigation({ colorScheme }: { readonly colorScheme: ColorSchemeName }) {
  const { isLoggedIn } = React.useContext(AuthContext);
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      { isLoggedIn ? <RootNavigator /> : <NotLoggedInNavigator /> }
    </NavigationContainer>
  );
}

const RootStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <RootStack.Navigator>
      <RootStack.Screen name='Root' component={BottomTabs} options={{ headerShown: false }} />
      <RootStack.Screen name='NotFound' component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <RootStack.Group screenOptions={{ presentation: 'modal' }}>
        { /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */ }
        { /* @ts-ignore */ }
        <RootStack.Screen name='NewCompetitionScreen' component={NewCompetitionScreen} options={{ title: 'New competition' }} />
      </RootStack.Group>
    </RootStack.Navigator>
  );
}

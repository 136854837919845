import React, { useMemo } from 'react';
import { useColorScheme } from 'react-native';

const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const primary = '#444E82';
const primaryText = '#fff';
const secondary = '#95A3A4';
const secondaryText = '#fff';
const link = 'transparent';
const linkText = '#000';

export const LightTheme = {
  colors: {
    headerBackground: '#F2F3F8',
    headerColor: '#000000',
    headerIconColor: '#808080',
    tabBarBackground: '#fff',
    tabBarBorderColor: '#E7E7E7',
    tabBarColor: '#ccc',
    tabBarIconColor: '#ccc',
    tabBarActiveBackground: '#fff',
    tabBarActiveColor: '#000',
    tabBarActiveIconColor: 'red',
    title: '#000000',
    text: '#242941',
    muted: '#95A3A4',
    icon: '#808080',
    iconButtonBackground: 'transparent',
    iconButtonBorder: 'transparent',
    iconButtonColor: '#fff',
    background: '#F2F3F8',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    cardBackground: '#fff',
    cardBorder: '#E7E7E7',
    listBackground: 'transparent',
    listBorder: 'transparent',
    listRowBackground: 'transparent',
    listRowBorder: 'transparent',
    listCellBackground: 'transparent',
    listCellBorder: 'transparent',
    inputBackground: '#F2F3F8',
    inputBackgroundFocus: '#E7E7EC',
    inputBorderColor: '#F2F3F8',
    inputBorderFocus: '#E7E7EC',
    inputPlaceholderColor: '#7B85B5',
    inputColor: '#444E82',
    inputColorFocus: '#444E82',
    buttonCreateBackground: '#444E82',
    buttonCreateIcon: '#fff',
    progressBackground: '#F2F3F8',
    progressBorder: 'transparent',
    progressStepBackground: '#444E82',
    progressStepBorder: 'transparent',
    primary,
    primaryText,
    secondary,
    secondaryText,
    link,
    linkText,
  },
};

export type Theme = typeof LightTheme

export const DarkTheme: Partial<typeof LightTheme> = {
  ...LightTheme,
  colors: {
    ...LightTheme.colors,
    headerBackground: '#242941',
    headerColor: '#FFFFFF',
    headerIconColor: '#fff',
    title: '#fff',
    text: '#E5E7F0',
    icon: '#808080',
    iconButtonColor: '#fff',
    iconButtonBackground: 'transparent',
    iconButtonBorder: 'transparent',
    background: '#242941',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    cardBackground: '#2E3353',
    cardBorder: '#515880',
    listBackground: 'transparent',
    listBorder: 'transparent',
    listRowBackground: 'transparent',
    listRowBorder: 'transparent',
    listCellBackground: 'transparent',
    listCellBorder: 'transparent',
    inputBackground: '#1F233E',
    inputBackgroundFocus: '#131525',
    inputBorderColor: '#1F233E',
    inputBorderFocus: '#1F233E',
    inputPlaceholderColor: '#9094A6',
    inputColor: '#BDC2DA',
    inputColorFocus: '#BDC2DA',
    buttonCreateBackground: '#515880',
    buttonCreateIcon: '#fff',
    progressBackground: '#F2F3F8',
    progressBorder: 'transparent',
    progressStepBackground: '#444E82',
    progressStepBorder: 'transparent',
  },
};

export const ThemeContext = React.createContext<Theme>(LightTheme);

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const colorScheme = useColorScheme();
  const theme = useMemo(() => ({
    ...LightTheme,
    ...(colorScheme === 'dark' ? DarkTheme : LightTheme),
  }), [colorScheme]);

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);

export { primary, secondary };

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as PaperProvider, DefaultTheme, DarkTheme } from 'react-native-paper';

import AuthProvider from './contexts/AuthContext';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { ThemeProvider } from './constants/Theme';

import type { Theme as PaperThemeType } from 'react-native-paper/lib/typescript/types';
import './utils/syncInBackground';

const theme: PaperThemeType = { ...DefaultTheme, colors: { ...DefaultTheme.colors } };
const darkTheme: PaperThemeType = { ...DarkTheme, colors: { ...DarkTheme.colors, primary: 'red' } };

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <PaperProvider theme={colorScheme === 'dark' ? darkTheme : theme}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </SafeAreaProvider>
        </PaperProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

import { StyleSheet } from 'react-native';

import {
  primary, secondary,
} from './Theme';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    // backgroundColor: '#fff',
  },
});

const direction = StyleSheet.create({
  horizontal: {
    flexDirection: 'row',
  },
  vertical: {
    flexDirection: 'column',
  },
  test: {
    backgroundColor: 'red',
  },
});

const typo = StyleSheet.create({
  pageTitle: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 32,
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
  },
  title3: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text: {
  },
  date: {
    color: 'rgba(0,0,0,0.5)',
  },
});

export {
  styles, typo, direction,
};

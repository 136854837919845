import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

// import { useTheme } from '../constants/Theme';

const styles = StyleSheet.create({
  group: {
    gap: 0,
    flex: 1,
  },
});

export const Group: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle
  /* readonly style?: ViewStyle
    readonly gap: number,
    readonly flexGrow: number,
    readonly flexDirection: string,
    readonly alignItems: string,
    readonly justifyContent: string */}>
> = ({ children, style }) => (
  <View style={[styles.group, style]}>
    {children}
  </View>
);

export default Group;

import dayjs from 'dayjs';
import React, { useState } from 'react';
import DateTimePicker from '@react-native-community/datetimepicker';
import {
  Button, ScrollView,
} from 'react-native';
import { Chip } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { Row } from '@kingstinct/react';

import { useUpsertCompetitionMutation, useAcceptInviteMutation } from '../clients/operations.generated';
import { QuantityType } from '../clients/__generated__/schema';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { styles } from '../constants/Styles';
import { TextInput } from '../components/TextInput';
import { useInviteToCompetitionLazy } from '../hooks/useInviteToCompetition';

type TypeChipProps = {
  readonly type: QuantityType,
  readonly selectedType: QuantityType,
  readonly onPress: (type: QuantityType) => void,
  readonly title: string
}

const TypeChip: React.FC<TypeChipProps> = ({
  type, onPress, selectedType, title,
}) => (
  <Chip
    onPress={() => onPress(type)}
    selected={selectedType === type}
  >
    {title}
  </Chip>
);

type Types = readonly {
  readonly type: QuantityType,
  readonly title: string
}[];

const types: Types = [
  { title: 'Kcal', type: QuantityType.activeEnergyBurned },
  { title: 'Steps', type: QuantityType.stepCount },
  { title: 'Distance', type: QuantityType.distanceWalkingRunning },
];

const NewCompetitionScreen: React.FC<RootTabScreenProps<'CompetitionsTab'>> = () => {
  const [, upsertCompetition] = useUpsertCompetitionMutation();
  const [name, setName] = useState('');
  const navigation = useNavigation();
  const invite = useInviteToCompetitionLazy();
  const [from, setFrom] = useState(dayjs().add(1, 'day').startOf('day').toDate());
  const [to, setTo] = useState(dayjs().add(8, 'days').endOf('day').toDate());

  const [selectedType, setType] = useState<QuantityType>(QuantityType.activeEnergyBurned);

  return (
    <ScrollView>
      <View>
        <View lightColor='#eee' darkColor='rgba(255,255,255,0.1)' />
        <Text>Competition name</Text>
        <TextInput
          autoCapitalize='sentences'
          autoFocus
          value={name}
          onChangeText={setName}
          placeholder='Namn'
        />
        <Text>Type</Text>
        <View style={{ flexDirection: 'row' }}>
          { types.map(({ title, type }) => (
            <TypeChip
              key={type}
              onPress={setType}
              title={title}
              type={type}
              selectedType={selectedType}
            />
          )) }
        </View>
        <Row>
          <Text>Starts</Text>
          <DateTimePicker
            value={from}
            minimumDate={dayjs().startOf('day').toDate()}
            onChange={({ nativeEvent }) => {
              if (nativeEvent.timestamp) {
                setFrom(dayjs(nativeEvent.timestamp).startOf('day').toDate());
              }
            }}
          />
        </Row>
        <Row>
          <Text>Ends</Text>
          <DateTimePicker
            value={to}
            minimumDate={dayjs().startOf('day').add(1, 'day').toDate()}
            onChange={({ nativeEvent }) => {
              if (nativeEvent.timestamp) {
                setTo(dayjs(nativeEvent.timestamp).startOf('day').toDate());
              }
            }}
          />
        </Row>

        <Button
          title='Create and invite friends'
          onPress={async () => {
            const res = await upsertCompetition({
              startDate: from.toISOString(),
              endDate: to.toISOString(),
              name,
              quantityType: selectedType,
              workoutTypes: [],
            });

            if (res.data) {
              navigation.goBack();
              await invite(res.data.upsertCompetition._id);
            }
          }}
        />
      </View>
    </ScrollView>
  );
};

export default NewCompetitionScreen;

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   title: {
//     fontSize: 20,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 30,
//     height: 1,
//     width: '80%',
//   },
// });

import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

// import { useTheme } from '../constants/Theme';

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingTop: 16,
    flex: 1,
    gap: 16,
    width: '100%',
  },
});

export const Container: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle
  /* readonly gap: number,
  readonly flexGrow: number,
  readonly padding: number,
  readonly flexDirection: string,
  readonly justifyContent: string,
  readonly alignItems: string } */}>
> = ({
  children, style,
}) => (
  <View style={[styles.container, style]}>
    {children}
  </View>
);

export default Container;

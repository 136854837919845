import Healthkit, {
  HKAuthorizationRequestStatus, HKQuantityTypeIdentifier, HKWorkoutRouteTypeIdentifier, HKWorkoutTypeIdentifier,
} from '@kingstinct/react-native-healthkit';

export const METRICS_TO_READ = [
  HKQuantityTypeIdentifier.activeEnergyBurned,
  HKQuantityTypeIdentifier.stepCount,
  HKQuantityTypeIdentifier.distanceWalkingRunning,
  HKQuantityTypeIdentifier.distanceCycling,
  HKQuantityTypeIdentifier.distanceSwimming,
  HKQuantityTypeIdentifier.swimmingStrokeCount,
  HKWorkoutTypeIdentifier,
];

export const requestHealthkitAccessIfNeeded = async () => {
  const response = await Healthkit.getRequestStatusForAuthorization(METRICS_TO_READ);

  if (response === HKAuthorizationRequestStatus.shouldRequest) {
    await Healthkit.requestAuthorization(METRICS_TO_READ);
  }
};

export default requestHealthkitAccessIfNeeded;

import dayjs from 'dayjs';
import React from 'react';
import {
  View, StyleSheet, Text, Pressable,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import { CompetitionDefaultFragment } from '../clients/operations.generated';
import { typo, direction, styles } from '../constants/Styles';
import useInviteToCompetition from '../hooks/useInviteToCompetition';
import { Card } from './Card';

const Competition: React.FC<{ readonly competition: CompetitionDefaultFragment, readonly onPress: () => void }> = ({ competition, onPress }) => {
  const inviteToCompetition = useInviteToCompetition(competition._id);
  return (
    <Pressable onPress={onPress}>
      <Card>
        <Text style={typo.title3}>{competition.name}</Text>
        <Text style={typo.date}>
          {dayjs(competition.startDate).format('YYYY-MM-DD')}
          {' - '}
          {dayjs(competition.endDate).format('YYYY-MM-DD')}
        </Text>
        <Ionicons
          onPress={inviteToCompetition}
          name='share-outline'
          size={16}
          color='#000'
        />
        <View style={direction.horizontal}>

          <View key={competition.quantityType} style={direction.vertical}>
            {competition.leaderboard.map((e) => (
              <View key={e.user._id} style={direction.test}>
                <Text>{e.score.value}</Text>
                <Text>{ competition.quantityType.toString() }</Text>
                <Text>{e.user.displayName}</Text>
                <Text>10000 km</Text>
              </View>
            ))}

          </View>
        </View>
        <Text style={typo.text}>
          Participants:
          {' '}
          {competition.leaderboard.length}
        </Text>
      </Card>
    </Pressable>
  );
};

export default Competition;

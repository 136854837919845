import React, { useCallback, useContext, useState } from 'react';
import { View, Text } from 'react-native';
import useAlert from '@kingstinct/react/hooks/useAlert';

import { useLoginConfirmMutation } from '../clients/operations.generated';
import { AuthContext } from '../contexts/AuthContext';
import { StyledText, StyledTitle } from './Typo';
import { TextInput } from './TextInput';
import Button from './Button';
import Container from './Container';
import Card from './Card';

const LoginConfirm: React.FC<{readonly email:string}> = ({ email }) => {
  const [code, setCode] = useState('');
  const { login } = useContext(AuthContext);
  const [, loginConfirm] = useLoginConfirmMutation();
  const alert = useAlert();
  const onSubmit = useCallback(async () => {
    console.log('confirming with', { code, email });
    const { data, error } = await loginConfirm({
      email,
      code,
    });
    if (data?.loginConfirm.__typename === 'LoginConfirmSuccessfulResponse') {
      login(data.loginConfirm.accessToken);
    }
    if (error) {
      await alert('Login failed', error.message);
    }
  }, [code, email, loginConfirm, login, alert]);

  return (
    <Container>
      <Card>
        <StyledTitle size='huge'>Soon to be fit & healthy with friends</StyledTitle>
        <TextInput
          placeholder='Login Code'
          value={code}
          keyboardType='numbers-and-punctuation'
          onChangeText={setCode}
          onSubmitEditing={onSubmit}
          enablesReturnKeyAutomatically
          returnKeyType='send'
        />
        <Button title='Continue' variant='primary' onPress={onSubmit} />
      </Card>
    </Container>
  );
};

export default LoginConfirm;

/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import { Text as DefaultText, View as DefaultView } from 'react-native';

import { Theme, useTheme } from '../constants/Theme';
import useColorScheme from '../hooks/useColorScheme';

export function useThemeColor(
  props: { readonly light?: string; readonly dark?: string },
  colorName: keyof Theme['colors'],
) {
  const theme = useTheme();
  const darkOrLight = useColorScheme();
  const colorFromProps = props[darkOrLight];

  if (colorFromProps) {
    return colorFromProps;
  }
  return theme.colors[colorName];
}

type ThemeProps = {
  readonly lightColor?: string;
  readonly darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
  const {
    style, lightColor, darkColor, ...otherProps
  } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const {
    style, lightColor, darkColor, ...otherProps
  } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

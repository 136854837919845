import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';
import Healthkit from '@kingstinct/react-native-healthkit';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { syncAllMetrics, UpsertSamplesFn, UpsertWorkoutSamplesFn } from '../hooks/useTriggerSync';
import createClientWithToken from '../clients/urql';
import { getAuthToken } from '../contexts/AuthContext';
import {
  UpsertSamplesDocument, UpsertSamplesMutation, UpsertSamplesMutationVariables, UpsertWorkoutsMutation, UpsertWorkoutsMutationVariables,
} from '../clients/operations.generated';
import { requestHealthkitAccessIfNeeded } from './requestHealthkitAccessIfNeeded';

const BACKGROUND_FETCH_TASK = 'background-sync';

// 1. Define the task by providing a name and the function that should be executed
// Note: This needs to be called in the global scope (e.g outside of your React components)
TaskManager.defineTask(BACKGROUND_FETCH_TASK, async () => {
  const now = Date.now();

  console.log(`Got background fetch call at date: ${new Date(now).toISOString()}`);

  const token = await getAuthToken();
  if (token && await Healthkit.isHealthDataAvailable() && await Healthkit.canAccessProtectedData()) {
    const client = createClientWithToken(token);

    const upsertSamples: UpsertSamplesFn = async (variables, context) => {
      const result = client.mutation<UpsertSamplesMutation, UpsertSamplesMutationVariables>(
        UpsertSamplesDocument,
        variables,
        context,
      ).toPromise();

      return result;
    };

    const upsertWorkoutSamples: UpsertWorkoutSamplesFn = async (variables, context) => {
      const result = client.mutation<UpsertWorkoutsMutation, UpsertWorkoutsMutationVariables>(
        UpsertSamplesDocument,
        variables,
        context,
      ).toPromise();

      return result;
    };

    await syncAllMetrics(upsertSamples, upsertWorkoutSamples);

    return BackgroundFetch.BackgroundFetchResult.NewData;
  }

  // Be sure to return the successful result type!
  return BackgroundFetch.BackgroundFetchResult.NoData;
});

// 2. Register the task at some point in your app by providing the same name,
// and some configuration options for how the background fetch should behave
// Note: This does NOT need to be in the global scope and CAN be used in your React components!
export function registerBackgroundFetchAsync() {
  return BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
    minimumInterval: 60 * 15, // 15 minutes
    stopOnTerminate: false, // android only,
    startOnBoot: true, // android only
  });
}

// 3. (Optional) Unregister tasks by specifying the task name
// This will cancel any future background fetch calls that match the given name
// Note: This does NOT need to be in the global scope and CAN be used in your React components!
export function unregisterBackgroundFetchAsync() {
  return BackgroundFetch.unregisterTaskAsync(BACKGROUND_FETCH_TASK);
}

export const useToggleBackgroundSync = () => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'ios') {
      void TaskManager.isTaskRegisteredAsync(BACKGROUND_FETCH_TASK).then(setIsEnabled);
    }
  }, []);

  const toggleOrSet = useCallback((enable?: boolean) => {
    if (enable === undefined) {
      setIsEnabled((wasEnabled) => {
        void setBackgroundSync(!wasEnabled);
        return !wasEnabled;
      });
    } else if (enable) {
      void setBackgroundSync(true);
      setIsEnabled(true);
    } else {
      void setBackgroundSync(false);
      setIsEnabled(false);
    }
  }, []);

  return [isEnabled, toggleOrSet] as const;
};

export const setBackgroundSync = async (enabled:boolean) => {
  if (enabled) {
    await Promise.all([
      requestHealthkitAccessIfNeeded(),
      registerBackgroundFetchAsync(),
    ]);
  } else {
    await unregisterBackgroundFetchAsync();
  }
};

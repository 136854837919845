import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { ThemeContext, useTheme } from '../constants/Theme';
import { StyledText, StyledTitle } from './Typo';

const styles = StyleSheet.create({
  progress: {
    height: 8,
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 200,
  },
  step: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 200,
  },
});

export const Step: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.step, {
        backgroundColor: theme.colors.progressStepBackground,
        borderColor: theme.colors.progressStepBorder,
      }, style]}
      >
        {children}
      </View>
    </ThemeContext.Provider>
  );
};

export const Progress: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.progress, {
        backgroundColor: theme.colors.progressBackground,
        borderColor: theme.colors.progressBorder,
      }, style]}
      >
        <Step style={{ width: '50%' }} />
      </View>
    </ThemeContext.Provider>
  );
};

export default Progress;

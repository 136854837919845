import { StyleSheet } from 'react-native';

import LoginConfirm from '../components/LoginConfirm';
import { View } from '../components/Themed';
import { LoginScreenProps } from '../types';
import { styles } from '../constants/Styles';

export default function LoginConfirmScreen({ route: { params: { email } } }: LoginScreenProps<'LoginConfirm'>) {
  return (
    <View style={styles.wrapper}>
      <LoginConfirm email={email} />
    </View>
  );
}

import React, { useCallback, useState } from 'react';

import Button from './Button';
import { useLoginRequestMutation } from '../clients/operations.generated';
import { StyledText, StyledTitle } from './Typo';
import { TextInput } from './TextInput';
import Container from './Container';
import Card from './Card';

const LoginRequest: React.FC<{readonly onEmailRequested: (email: string) => void}> = ({ onEmailRequested }) => {
  const [email, setEmail] = useState('');
  const [, loginRequest] = useLoginRequestMutation();
  const onSubmit = useCallback(async () => {
    await loginRequest({
      email,
    });
    onEmailRequested(email);
  }, [email, loginRequest, onEmailRequested]);

  return (
    <Container>
      <Card>
        <StyledTitle size='huge'>Get fit & healthier with friends</StyledTitle>
        <TextInput
          placeholder='Email'
          value={email}
          keyboardType='email-address'
          onChangeText={setEmail}
          onSubmitEditing={onSubmit}
          enablesReturnKeyAutomatically
          autoCapitalize='none'
          returnKeyType='send'
        />
        <Button title='Continue' variant='primary' onPress={onSubmit} />
      </Card>
      <StyledText>New user? Just enter your email and we’ll send you the magic link - that’s all you need to get started!</StyledText>
    </Container>
  );
};

export default LoginRequest;

import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { ThemeContext, useTheme } from '../constants/Theme';
import { StyledText, StyledTitle } from './Typo';

const styles = StyleSheet.create({
  list: {
    marginBottom: 0,
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 0,
    gap: 16,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 0,
    gap: 8,
  },
  cell: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 0,
    gap: 16,
    flex: 1,
  },
});

export const ListRow: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.row, {
        backgroundColor: theme.colors.listRowBackground,
        borderColor: theme.colors.listRowBorder,
      }, style]}
      >
        {children}
      </View>
    </ThemeContext.Provider>
  );
};

export const ListCell: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.cell, {
        backgroundColor: theme.colors.listCellBackground,
        borderColor: theme.colors.listCellBorder,
      }, style]}
      >
        <StyledText>{children}</StyledText>
      </View>
    </ThemeContext.Provider>
  );
};

export const List: React.FC<PropsWithChildren<{
  readonly style?: ViewStyle}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.list, {
        backgroundColor: theme.colors.listBackground,
        borderColor: theme.colors.listBorder,
      }, style]}
      >
        {children}
      </View>
    </ThemeContext.Provider>
  );
};

export default List;

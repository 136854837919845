import React, { useState } from 'react';
import {
  RefreshControl, ScrollView, StyleSheet,
} from 'react-native';

// import EditScreenInfo from '../components/EditScreenInfo';
import { View } from '../components/Themed';
import { CompetitionsScreenProps, RootTabScreenProps } from '../types';
import { useCompetitionsQuery } from '../clients/operations.generated';
import Competition from '../components/Competition';
import List, { ListRow, ListCell } from '../components/List';
import Progress from '../components/Progress';
import Card from '../components/Card';
import Group from '../components/Group';
import Button from '../components/Button';
import { StyledText, StyledTitle } from '../components/Typo';

export default function CompetitionsScreen({ navigation }: CompetitionsScreenProps<'Competitions'>) {
  const [{ data, fetching }, fetch] = useCompetitionsQuery();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <ScrollView
      refreshControl={(
        <RefreshControl
          refreshing={fetching}
          onRefresh={fetch}
        />
      )}
    >
      <View style={styles.container}>

        <Card>

          <Group style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 0 }}>
            <Group style={{ alignItems: 'flex-start', justifyContent: 'center', gap: 16 }}>
              <StyledTitle size='md'>Tjockisarnas kamp sommaren 2024</StyledTitle>
            </Group>
            <Group style={{
              flexGrow: 0, flexBasis: 'auto', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <Button icon='down' variant='icon' onPress={() => setOpen(true)} />
              {/* <Button icon={{ open ? 'up' : 'down'}} size='' variant='icon' onPress={() => setOpen(true)} /> */}
            </Group>
          </Group>

          <StyledText>160 km challenge</StyledText>
          <List>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                4 participants
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                3 days left
              </ListCell>
            </ListRow>
            <ListRow>
              <Progress />
            </ListRow>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                #02 with 10 km behind
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                110 km
              </ListCell>
            </ListRow>
          </List>
        </Card>

        {open && (
          <List>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                1.
              </ListCell>
              <ListCell>
                Petter
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                120 km
              </ListCell>
            </ListRow>
            <ListRow>
              <Progress />
            </ListRow>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                2.
              </ListCell>
              <ListCell>
                Olle
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                100 km
              </ListCell>
            </ListRow>
            <ListRow>
              <Progress />
            </ListRow>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                3.
              </ListCell>
              <ListCell>
                Robert
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                990 km
              </ListCell>
            </ListRow>
            <ListRow>
              <Progress />
            </ListRow>
            <ListRow>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                4.
              </ListCell>
              <ListCell>
                Daniel
              </ListCell>
              <ListCell style={{ flexGrow: 0, flexBasis: 'auto' }}>
                70 km
              </ListCell>
            </ListRow>
            <ListRow>
              <Progress />
            </ListRow>
          </List>
        )}

        {data?.me.competitions.map((e) => (
          <Competition
            key={e._id}
            competition={e}
            onPress={() => {}}
          />
        )) }
        {/* <View style={styles.separator} lightColor='#eee' darkColor='rgba(255,255,255,0.1)' /> */}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  // separator: {
  //   marginVertical: 30,
  //   height: 1,
  //   width: '80%',
  // },
});

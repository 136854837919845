import type * as Types from './__generated__/schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginRequestMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type LoginRequestMutation = { readonly __typename: 'Mutation', readonly loginRequest: { readonly __typename: 'EmailNotValidError', readonly message: string } | { readonly __typename: 'LoginRequestSuccessResponse', readonly success: boolean } };

export type LoginConfirmMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type LoginConfirmMutation = { readonly __typename: 'Mutation', readonly loginConfirm: { readonly __typename: 'CodeNotValidError' } | { readonly __typename: 'EmailNotValidError' } | { readonly __typename: 'LoginConfirmSuccessfulResponse', readonly accessToken: string } | { readonly __typename: 'LoginFailedError' } };

export type InviteToCompetitionMutationVariables = Types.Exact<{
  competitionId: Types.Scalars['ObjectId'];
}>;


export type InviteToCompetitionMutation = { readonly __typename: 'Mutation', readonly generateCompetitionInvite: { readonly __typename: 'ForbiddenError', readonly message: string } | { readonly __typename: 'InvitationResponseSuccess', readonly invitationToken: string, readonly invitationUrl: string } | { readonly __typename: 'NotFoundError', readonly message: string } };

export type MetricsQueryVariables = Types.Exact<{
  from: Types.Scalars['DateTime'];
}>;


export type MetricsQuery = { readonly __typename: 'Query', readonly me: { readonly __typename: 'User', readonly displayName: string, readonly email: string, readonly stepCount: { readonly __typename: 'StatWithValue', readonly unit: Types.Unit, readonly value: number }, readonly distanceWalkingRunning: { readonly __typename: 'StatWithValue', readonly unit: Types.Unit, readonly value: number }, readonly distanceSwimming: { readonly __typename: 'StatWithValue', readonly unit: Types.Unit, readonly value: number }, readonly quantitySamples: ReadonlyArray<{ readonly __typename: 'QuantitySample', readonly startDate: string, readonly value: number }> } };

export type UpsertSamplesMutationVariables = Types.Exact<{
  samples: ReadonlyArray<Types.QuantitySamplesUpsertValue> | Types.QuantitySamplesUpsertValue;
  quantityType: Types.QuantityType;
  sourceType: Types.SourceType;
  unit: Types.UnitInternal;
  timestamp?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type UpsertSamplesMutation = { readonly __typename: 'Mutation', readonly upsertQuantitySamples: { readonly __typename: 'UpsertedResult', readonly upsertedCount: number, readonly insertedCount: number, readonly modifiedCount: number } };

export type CompetitionDefaultFragment = { readonly __typename: 'Competition', readonly _id: string, readonly name: string, readonly quantityType: Types.QuantityType, readonly createdAt: string, readonly modifiedAt: string, readonly createdById: string, readonly startDate: string, readonly endDate: string, readonly leaderboard: ReadonlyArray<{ readonly __typename: 'LeaderboardEntry', readonly user: { readonly __typename: 'RestrictedUser', readonly displayName: string, readonly avatarUrl: string, readonly _id: string }, readonly score: { readonly __typename: 'StatWithValue', readonly value: number, readonly unit: Types.Unit } }> };

export type AcceptInviteMutationVariables = Types.Exact<{
  inviteToken: Types.Scalars['String'];
}>;


export type AcceptInviteMutation = { readonly __typename: 'Mutation', readonly acceptCompetitionInvite: { readonly __typename: 'Competition', readonly _id: string, readonly name: string, readonly quantityType: Types.QuantityType, readonly createdAt: string, readonly modifiedAt: string, readonly createdById: string, readonly startDate: string, readonly endDate: string, readonly leaderboard: ReadonlyArray<{ readonly __typename: 'LeaderboardEntry', readonly user: { readonly __typename: 'RestrictedUser', readonly displayName: string, readonly avatarUrl: string, readonly _id: string }, readonly score: { readonly __typename: 'StatWithValue', readonly value: number, readonly unit: Types.Unit } }> } | { readonly __typename: 'ForbiddenError' } | { readonly __typename: 'NotFoundError' } };

export type CompetitionByIdQueryVariables = Types.Exact<{
  competitionId: Types.Scalars['ObjectId'];
}>;


export type CompetitionByIdQuery = { readonly __typename: 'Query', readonly competitionById: { readonly __typename: 'Competition', readonly _id: string, readonly name: string, readonly quantityType: Types.QuantityType, readonly createdAt: string, readonly modifiedAt: string, readonly createdById: string, readonly startDate: string, readonly endDate: string, readonly leaderboard: ReadonlyArray<{ readonly __typename: 'LeaderboardEntry', readonly user: { readonly __typename: 'RestrictedUser', readonly displayName: string, readonly avatarUrl: string, readonly _id: string }, readonly score: { readonly __typename: 'StatWithValue', readonly value: number, readonly unit: Types.Unit } }> } | { readonly __typename: 'NotFoundError' } };

export type CompetitionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompetitionsQuery = { readonly __typename: 'Query', readonly me: { readonly __typename: 'User', readonly competitions: ReadonlyArray<{ readonly __typename: 'Competition', readonly _id: string, readonly name: string, readonly quantityType: Types.QuantityType, readonly createdAt: string, readonly modifiedAt: string, readonly createdById: string, readonly startDate: string, readonly endDate: string, readonly leaderboard: ReadonlyArray<{ readonly __typename: 'LeaderboardEntry', readonly user: { readonly __typename: 'RestrictedUser', readonly displayName: string, readonly avatarUrl: string, readonly _id: string }, readonly score: { readonly __typename: 'StatWithValue', readonly value: number, readonly unit: Types.Unit } }> }> } };

export type UpsertCompetitionMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ObjectId']>;
  name: Types.Scalars['String'];
  endDate: Types.Scalars['DateTime'];
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  quantityType: Types.QuantityType;
  workoutTypes: ReadonlyArray<Types.WorkoutActivityType> | Types.WorkoutActivityType;
}>;


export type UpsertCompetitionMutation = { readonly __typename: 'Mutation', readonly upsertCompetition: { readonly __typename: 'Competition', readonly _id: string, readonly name: string, readonly quantityType: Types.QuantityType, readonly createdAt: string, readonly modifiedAt: string, readonly createdById: string, readonly startDate: string, readonly endDate: string, readonly leaderboard: ReadonlyArray<{ readonly __typename: 'LeaderboardEntry', readonly user: { readonly __typename: 'RestrictedUser', readonly displayName: string, readonly avatarUrl: string, readonly _id: string }, readonly score: { readonly __typename: 'StatWithValue', readonly value: number, readonly unit: Types.Unit } }> } };

export type UpsertWorkoutsMutationVariables = Types.Exact<{
  workouts: ReadonlyArray<Types.WorkoutSampleUpsert> | Types.WorkoutSampleUpsert;
}>;


export type UpsertWorkoutsMutation = { readonly __typename: 'Mutation', readonly upsertWorkoutSamples: { readonly __typename: 'UpsertedResult', readonly items: ReadonlyArray<{ readonly __typename: 'UpsertedItem', readonly result: Types.UpsertOperationType }> } };

export const CompetitionDefaultFragmentDoc = gql`
    fragment CompetitionDefault on Competition {
  _id
  name
  quantityType
  createdAt
  modifiedAt
  createdById
  startDate
  endDate
  leaderboard {
    user {
      displayName
      avatarUrl
      _id
    }
    score {
      value
      unit
    }
  }
}
    `;
export const LoginRequestDocument = gql`
    mutation LoginRequest($email: String!) {
  loginRequest(email: $email) {
    ... on LoginRequestSuccessResponse {
      success
    }
    ... on Error {
      message
    }
  }
}
    `;

export function useLoginRequestMutation() {
  return Urql.useMutation<LoginRequestMutation, LoginRequestMutationVariables>(LoginRequestDocument);
};
export const LoginConfirmDocument = gql`
    mutation LoginConfirm($email: String!, $code: String!) {
  loginConfirm(email: $email, code: $code) {
    ... on LoginConfirmSuccessfulResponse {
      accessToken
    }
  }
}
    `;

export function useLoginConfirmMutation() {
  return Urql.useMutation<LoginConfirmMutation, LoginConfirmMutationVariables>(LoginConfirmDocument);
};
export const InviteToCompetitionDocument = gql`
    mutation InviteToCompetition($competitionId: ObjectId!) {
  generateCompetitionInvite(competitionId: $competitionId) {
    ... on Error {
      message
    }
    ... on InvitationResponseSuccess {
      invitationToken
      invitationUrl
    }
  }
}
    `;

export function useInviteToCompetitionMutation() {
  return Urql.useMutation<InviteToCompetitionMutation, InviteToCompetitionMutationVariables>(InviteToCompetitionDocument);
};
export const MetricsDocument = gql`
    query Metrics($from: DateTime!) {
  me {
    displayName
    email
    ... on User {
      stepCount: quantityStat(
        quantityType: stepCount
        statistic: SUM
        fromDate: $from
      ) {
        unit
        value
      }
      distanceWalkingRunning: quantityStat(
        quantityType: distanceWalkingRunning
        statistic: SUM
        fromDate: $from
      ) {
        unit
        value
      }
      distanceSwimming: quantityStat(
        quantityType: distanceSwimming
        statistic: SUM
        fromDate: $from
      ) {
        unit
        value
      }
      quantitySamples(quantityType: stepCount) {
        startDate
        value
      }
    }
  }
}
    `;

export function useMetricsQuery(options: Omit<Urql.UseQueryArgs<MetricsQueryVariables>, 'query'>) {
  return Urql.useQuery<MetricsQuery, MetricsQueryVariables>({ query: MetricsDocument, ...options });
};
export const UpsertSamplesDocument = gql`
    mutation UpsertSamples($samples: [QuantitySamplesUpsertValue!]!, $quantityType: QuantityType!, $sourceType: SourceType!, $unit: UnitInternal!, $timestamp: DateTime) {
  upsertQuantitySamples(
    samples: $samples
    quantityType: $quantityType
    sourceType: $sourceType
    unit: $unit
    timestamp: $timestamp
  ) {
    upsertedCount
    insertedCount
    modifiedCount
  }
}
    `;

export function useUpsertSamplesMutation() {
  return Urql.useMutation<UpsertSamplesMutation, UpsertSamplesMutationVariables>(UpsertSamplesDocument);
};
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($inviteToken: String!) {
  acceptCompetitionInvite(inviteToken: $inviteToken) {
    ...CompetitionDefault
  }
}
    ${CompetitionDefaultFragmentDoc}`;

export function useAcceptInviteMutation() {
  return Urql.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument);
};
export const CompetitionByIdDocument = gql`
    query CompetitionById($competitionId: ObjectId!) {
  competitionById(competitionId: $competitionId) {
    ...CompetitionDefault
  }
}
    ${CompetitionDefaultFragmentDoc}`;

export function useCompetitionByIdQuery(options: Omit<Urql.UseQueryArgs<CompetitionByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<CompetitionByIdQuery, CompetitionByIdQueryVariables>({ query: CompetitionByIdDocument, ...options });
};
export const CompetitionsDocument = gql`
    query Competitions {
  me {
    competitions {
      ...CompetitionDefault
    }
  }
}
    ${CompetitionDefaultFragmentDoc}`;

export function useCompetitionsQuery(options?: Omit<Urql.UseQueryArgs<CompetitionsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompetitionsQuery, CompetitionsQueryVariables>({ query: CompetitionsDocument, ...options });
};
export const UpsertCompetitionDocument = gql`
    mutation UpsertCompetition($id: ObjectId, $name: String!, $endDate: DateTime!, $startDate: DateTime, $quantityType: QuantityType!, $workoutTypes: [WorkoutActivityType!]!) {
  upsertCompetition(
    competition: {_id: $id, name: $name, endDate: $endDate, startDate: $startDate, quantityType: $quantityType, workoutTypes: $workoutTypes}
  ) {
    ...CompetitionDefault
  }
}
    ${CompetitionDefaultFragmentDoc}`;

export function useUpsertCompetitionMutation() {
  return Urql.useMutation<UpsertCompetitionMutation, UpsertCompetitionMutationVariables>(UpsertCompetitionDocument);
};
export const UpsertWorkoutsDocument = gql`
    mutation UpsertWorkouts($workouts: [WorkoutSampleUpsert!]!) {
  upsertWorkoutSamples(samples: $workouts) {
    items {
      result
    }
  }
}
    `;

export function useUpsertWorkoutsMutation() {
  return Urql.useMutation<UpsertWorkoutsMutation, UpsertWorkoutsMutationVariables>(UpsertWorkoutsDocument);
};
import LoginRequest from '../components/LoginRequest';
import { View } from '../components/Themed';
import { LoginScreenProps } from '../types';
import { styles } from '../constants/Styles';

export default function LoginRequestScreen({ navigation }: LoginScreenProps<'LoginRequest'>) {
  return (
    <View style={styles.wrapper}>
      <LoginRequest
        onEmailRequested={(email) => navigation.navigate('LoginConfirm', { email })}
      />
    </View>
  );
}

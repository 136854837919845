/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import AntDesign from '@expo/vector-icons/AntDesign';

import useColorScheme from '../hooks/useColorScheme';
import {
  RootTabParamList, RootTabScreenProps,
} from '../types';
import { useTheme } from '../constants/Theme';
import ProfileTabStack from './ProfileTabStack';
import CompetitionsTabStack from './CompetitionsTabStack';

const BottomTabNavigator = createBottomTabNavigator<RootTabParamList>();

function BottomTabs() {
  const colorScheme = useColorScheme();
  const theme = useTheme();

  return (
    <BottomTabNavigator.Navigator
      initialRouteName='CompetitionsTab'
      screenOptions={{
        // tabBarActiveTintColor: theme.colors.tabBarActiveTintColor,
      }}
    >
      <BottomTabNavigator.Screen
        name='CompetitionsTab'
        component={CompetitionsTabStack}
        options={({ navigation }: RootTabScreenProps<'CompetitionsTab'>) => ({
          title: 'Competitions',
          tabBarIcon: ({ color }) => <AntDesign name='Trophy' size={24} color='black' />,
          tabBarActiveTintColor: theme.colors.tabBarActiveColor,
          tabBarInactiveTintColor: theme.colors.tabBarColor,
          tabBarInactiveBackgroundColor: theme.colors.tabBarBackground,
          tabBarActiveBackgroundColor: theme.colors.tabBarActiveBackground,
          tabBarLabelPosition: 'below-icon',
          headerShown: false,
          tabBarStyle: {
            borderTopWidth: 1,
            borderTopColor: theme.colors.tabBarBorderColor,
          },
        })}
      />
      {/* <BottomTabNavigator.Screen
        name='NewCompetition'
        component={NewCompetitionScreen}
        options={{
          title: 'New',
          tabBarIcon: ({ color }) => <AntDesign name='plus' size={24} color='black' />,
          tabBarActiveTintColor: theme.colors.tabBarActiveColor,
          tabBarInactiveTintColor: theme.colors.tabBarColor,
          tabBarInactiveBackgroundColor: theme.colors.tabBarBackground,
          tabBarActiveBackgroundColor: theme.colors.tabBarActiveBackground,
          tabBarLabelPosition: 'below-icon',
          tabBarStyle: {
            borderTopWidth: 0,
          },
        }}
      /> */}
      <BottomTabNavigator.Screen
        name='ProfileTab'
        component={ProfileTabStack}
        options={{
          title: 'Profile',
          tabBarIcon: ({ color }) => <AntDesign name='user' size={24} color='black' />,
          tabBarActiveTintColor: theme.colors.tabBarActiveColor,
          headerShown: false,
          tabBarInactiveTintColor: theme.colors.tabBarColor,
          tabBarInactiveBackgroundColor: theme.colors.tabBarBackground,
          tabBarActiveBackgroundColor: theme.colors.tabBarActiveBackground,
          tabBarLabelPosition: 'below-icon',
          tabBarStyle: {
            borderTopWidth: 1,
            borderTopColor: theme.colors.tabBarBorderColor,
          },
        }}
      />
    </BottomTabNavigator.Navigator>
  );
}

export default BottomTabs;

import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { ThemeContext, useTheme } from '../constants/Theme';

const styles = StyleSheet.create({
  card: {
    fontWeight: 'bold',
    marginBottom: 32,
    width: '100%',
    padding: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    gap: 16,
  },
});

export const Card: React.FC<PropsWithChildren<{
  // readonly gap: number,
  readonly style?: ViewStyle
  /* readonly flexGrow: number,
  readonly flexDirection: ViewStyle['flexDirection'],
  readonly alignItems: ViewStyle['alignItems'],
readonly justifyContent: ViewStyle['justifyContent'] */}>
> = ({
  children, style,
}) => {
  const theme = useTheme();
  const childTheme = useMemo(() => ({ ...theme, colors: { ...theme.colors } }), [theme]);

  return (
    <ThemeContext.Provider value={childTheme}>
      <View style={[styles.card, {
        backgroundColor: theme.colors.cardBackground,
        borderColor: theme.colors.cardBorder,
        // alignItems,
        // flexDirection,
        // justifyContent,
        // gap,
        // flexGrow,
      }, style]}
      >
        {children}
      </View>
    </ThemeContext.Provider>
  );
};

export default Card;

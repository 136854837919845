import { useCallback } from 'react';
import Healthkit, {
  HKAuthorizationRequestStatus,
  HKQuantityTypeIdentifier,
  HKUnit,
  HKWorkoutRouteTypeIdentifier,
  HKUnits,
  HKWorkoutActivityType,
  HKWorkoutTypeIdentifier,
} from '@kingstinct/react-native-healthkit';
import dayjs from 'dayjs';
import { AppState } from 'react-native';
import groupBy from 'lodash/fp/groupBy';

import { useUpsertSamplesMutation, useUpsertWorkoutsMutation } from '../clients/operations.generated';
import {
  QuantitySamplesUpsertValue, QuantityType, SourceType, UnitInternal, WorkoutActivityType, WorkoutSampleUpsert,
} from '../clients/__generated__/schema';
import { METRICS_TO_READ } from '../utils/requestHealthkitAccessIfNeeded';

// eslint-disable-next-line
// @ts-ignore
const METRIC_MAPPING: Record<HKQuantityTypeIdentifier, QuantityType> = {
  [HKQuantityTypeIdentifier.activeEnergyBurned]: QuantityType.activeEnergyBurned,
  [HKQuantityTypeIdentifier.stepCount]: QuantityType.stepCount,
  [HKQuantityTypeIdentifier.distanceWalkingRunning]: QuantityType.distanceWalkingRunning,
  [HKQuantityTypeIdentifier.distanceCycling]: QuantityType.distanceCycling,
  [HKQuantityTypeIdentifier.distanceSwimming]: QuantityType.distanceSwimming,
  [HKQuantityTypeIdentifier.swimmingStrokeCount]: QuantityType.swimmingStrokeCount,
};

// eslint-disable-next-line
// @ts-ignore
const UNIT_FOR_METRIC: Record<HKQuantityTypeIdentifier, HKUnit> = {
  [HKQuantityTypeIdentifier.activeEnergyBurned]: 'kcal',
  [HKQuantityTypeIdentifier.stepCount]: HKUnits.Count,
  [HKQuantityTypeIdentifier.distanceWalkingRunning]: 'm',
  [HKQuantityTypeIdentifier.distanceCycling]: 'm',
  [HKQuantityTypeIdentifier.distanceSwimming]: 'm',
  [HKQuantityTypeIdentifier.swimmingStrokeCount]: HKUnits.Count,
};

// eslint-disable-next-line
// @ts-ignore
const UNIT_MAPPING: Record<HKUnit, UnitInternal> = {
  [HKUnits.Count]: UnitInternal.COUNT,
  kcal: UnitInternal.KILOCALORIE,
  mm: UnitInternal.MILLIMETER,
  m: UnitInternal.MILLIMETER,
};

const WORKOUT_MAPPING: Record<HKWorkoutActivityType, WorkoutActivityType> = {
  [HKWorkoutActivityType.americanFootball]: WorkoutActivityType.AMERICAN_FOOTBALL,
  [HKWorkoutActivityType.archery]: WorkoutActivityType.ARCHERY,
  [HKWorkoutActivityType.australianFootball]: WorkoutActivityType.AUSTRALIAN_FOOTBALL,
  [HKWorkoutActivityType.badminton]: WorkoutActivityType.BADMINTON,
  [HKWorkoutActivityType.baseball]: WorkoutActivityType.BASEBALL,
  [HKWorkoutActivityType.basketball]: WorkoutActivityType.BASKETBALL,
  [HKWorkoutActivityType.bowling]: WorkoutActivityType.BOWLING,
  [HKWorkoutActivityType.boxing]: WorkoutActivityType.BOXING,
  [HKWorkoutActivityType.climbing]: WorkoutActivityType.CLIMBING,
  [HKWorkoutActivityType.cricket]: WorkoutActivityType.CRICKET,
  [HKWorkoutActivityType.crossTraining]: WorkoutActivityType.CROSS_TRAINING,
  [HKWorkoutActivityType.curling]: WorkoutActivityType.CURLING,
  [HKWorkoutActivityType.cycling]: WorkoutActivityType.CYCLING,
  [HKWorkoutActivityType.dance]: WorkoutActivityType.DANCE,
  [HKWorkoutActivityType.danceInspiredTraining]: WorkoutActivityType.DANCE_INSPIRED_TRAINING,
  [HKWorkoutActivityType.elliptical]: WorkoutActivityType.ELLIPTICAL,
  [HKWorkoutActivityType.equestrianSports]: WorkoutActivityType.EQUESTRIAN_SPORTS,
  [HKWorkoutActivityType.fencing]: WorkoutActivityType.FENCING,
  [HKWorkoutActivityType.fishing]: WorkoutActivityType.FISHING,
  [HKWorkoutActivityType.functionalStrengthTraining]: WorkoutActivityType.FUNCTIONAL_STRENGTH_TRAINING,
  [HKWorkoutActivityType.golf]: WorkoutActivityType.GOLF,
  [HKWorkoutActivityType.gymnastics]: WorkoutActivityType.GYMNASTICS,
  [HKWorkoutActivityType.handball]: WorkoutActivityType.HANDBALL,
  [HKWorkoutActivityType.hiking]: WorkoutActivityType.HIKING,
  [HKWorkoutActivityType.hockey]: WorkoutActivityType.HOCKEY,
  [HKWorkoutActivityType.hunting]: WorkoutActivityType.HUNTING,
  [HKWorkoutActivityType.lacrosse]: WorkoutActivityType.LACROSSE,
  [HKWorkoutActivityType.martialArts]: WorkoutActivityType.MARTIAL_ARTS,
  [HKWorkoutActivityType.mindAndBody]: WorkoutActivityType.MIND_AND_BODY,
  [HKWorkoutActivityType.mixedMetabolicCardioTraining]: WorkoutActivityType.MIXED_METABOLIC_CARDIO_TRAINING,
  [HKWorkoutActivityType.paddleSports]: WorkoutActivityType.PADDLE_SPORTS,
  [HKWorkoutActivityType.play]: WorkoutActivityType.PLAY,
  [HKWorkoutActivityType.preparationAndRecovery]: WorkoutActivityType.PREPARATION_AND_RECOVERY,
  [HKWorkoutActivityType.racquetball]: WorkoutActivityType.RACQUETBALL,
  [HKWorkoutActivityType.rowing]: WorkoutActivityType.ROWING,
  [HKWorkoutActivityType.rugby]: WorkoutActivityType.RUGBY,
  [HKWorkoutActivityType.running]: WorkoutActivityType.RUNNING,
  [HKWorkoutActivityType.sailing]: WorkoutActivityType.SAILING,
  [HKWorkoutActivityType.skatingSports]: WorkoutActivityType.SKATING_SPORTS,
  [HKWorkoutActivityType.snowSports]: WorkoutActivityType.SNOW_SPORTS,
  [HKWorkoutActivityType.soccer]: WorkoutActivityType.SOCCER,
  [HKWorkoutActivityType.softball]: WorkoutActivityType.SOFTBALL,
  [HKWorkoutActivityType.squash]: WorkoutActivityType.SQUASH,
  [HKWorkoutActivityType.stairClimbing]: WorkoutActivityType.STAIR_CLIMBING,
  [HKWorkoutActivityType.surfingSports]: WorkoutActivityType.SURFING_SPORTS,
  [HKWorkoutActivityType.swimming]: WorkoutActivityType.SWIMMING,
  [HKWorkoutActivityType.tableTennis]: WorkoutActivityType.TABLE_TENNIS,
  [HKWorkoutActivityType.tennis]: WorkoutActivityType.TENNIS,
  [HKWorkoutActivityType.trackAndField]: WorkoutActivityType.TRACK_AND_FIELD,
  [HKWorkoutActivityType.traditionalStrengthTraining]: WorkoutActivityType.TRADITIONAL_STRENGTH_TRAINING,
  [HKWorkoutActivityType.volleyball]: WorkoutActivityType.VOLLEYBALL,
  [HKWorkoutActivityType.walking]: WorkoutActivityType.WALKING,
  [HKWorkoutActivityType.waterFitness]: WorkoutActivityType.WATER_FITNESS,
  [HKWorkoutActivityType.waterPolo]: WorkoutActivityType.WATER_POLO,
  [HKWorkoutActivityType.waterSports]: WorkoutActivityType.WATER_SPORTS,
  [HKWorkoutActivityType.wrestling]: WorkoutActivityType.WRESTLING,
  [HKWorkoutActivityType.yoga]: WorkoutActivityType.YOGA,
  [HKWorkoutActivityType.barre]: WorkoutActivityType.BARRE,
  [HKWorkoutActivityType.coreTraining]: WorkoutActivityType.CORE_TRAINING,
  [HKWorkoutActivityType.crossCountrySkiing]: WorkoutActivityType.CROSS_COUNTRY_SKIING,
  [HKWorkoutActivityType.downhillSkiing]: WorkoutActivityType.DOWNHILL_SKIING,
  [HKWorkoutActivityType.flexibility]: WorkoutActivityType.FLEXIBILITY,
  [HKWorkoutActivityType.highIntensityIntervalTraining]: WorkoutActivityType.HIGH_INTENSITY_INTERVAL_TRAINING,
  [HKWorkoutActivityType.jumpRope]: WorkoutActivityType.JUMP_ROPE,
  [HKWorkoutActivityType.kickboxing]: WorkoutActivityType.KICKBOXING,
  [HKWorkoutActivityType.pilates]: WorkoutActivityType.PILATES,
  [HKWorkoutActivityType.snowboarding]: WorkoutActivityType.SNOWBOARDING,
  [HKWorkoutActivityType.stairs]: WorkoutActivityType.STAIRS,
  [HKWorkoutActivityType.stepTraining]: WorkoutActivityType.STEP_TRAINING,
  [HKWorkoutActivityType.wheelchairWalkPace]: WorkoutActivityType.WHEELCHAIR_WALK_PACE,
  [HKWorkoutActivityType.wheelchairRunPace]: WorkoutActivityType.WHEELCHAIR_RUN_PACE,
  [HKWorkoutActivityType.taiChi]: WorkoutActivityType.TAI_CHI,
  [HKWorkoutActivityType.mixedCardio]: WorkoutActivityType.MIXED_CARDIO,
  [HKWorkoutActivityType.handCycling]: WorkoutActivityType.HAND_CYCLING,
  [HKWorkoutActivityType.discSports]: WorkoutActivityType.DISC_SPORTS,
  [HKWorkoutActivityType.fitnessGaming]: WorkoutActivityType.FITNESS_GAMING,
  [HKWorkoutActivityType.other]: WorkoutActivityType.OTHER,
};

export type UpsertSamplesFn = ReturnType<(typeof useUpsertSamplesMutation)>[1]
export type UpsertWorkoutSamplesFn = ReturnType<(typeof useUpsertWorkoutsMutation)>[1]

export const syncMetric = (
  upsertSamples: UpsertSamplesFn,
  upsertWorkoutSamples: UpsertWorkoutSamplesFn,
) => async (quantityType: HKQuantityTypeIdentifier | typeof HKWorkoutTypeIdentifier) => {
  if (quantityType === HKWorkoutTypeIdentifier) {
    const workouts = await Healthkit.queryWorkouts({
      from: dayjs().subtract(1, 'week').toDate(),
      to: new Date(),
    });

    if (workouts.length > 0) {
      const response = await upsertWorkoutSamples({
        workouts: workouts.map<WorkoutSampleUpsert>((w) => ({
          workoutActivityType: WORKOUT_MAPPING[w.workoutActivityType],
          appleHealthKitUUID: w.uuid,
          endDate: dayjs(w.endDate).toISOString(),
          startDate: dayjs(w.startDate).toISOString(),
          timestamp: dayjs().toISOString(),
          sourceType: SourceType.HEALTHKIT,
        })),
      });

      if (response.data) {
        console.log('DATA', response.data);
      }

      if (response.error) {
        console.error(response.error);
      }
    }
  } else {
    const { samples: lastWeekData, newAnchor, deletedSamples } = await Healthkit.queryQuantitySamplesWithAnchor(quantityType, {
      from: dayjs().subtract(1, 'week').toDate(),
      to: new Date(),
      unit: UNIT_FOR_METRIC[quantityType],
    });
    if (lastWeekData.length > 0) {
      const lastWeekDataGroupedByUnit = groupBy('unit', lastWeekData);

      await Promise.all(Object.keys(lastWeekDataGroupedByUnit).map(async (unitRaw) => {
        const values = lastWeekDataGroupedByUnit[unitRaw];
        const unit = UNIT_MAPPING[unitRaw as HKUnit];
        if (!unit) {
          console.log('no mapping found for', unitRaw);
        }
        const response = await upsertSamples({
          quantityType: METRIC_MAPPING[quantityType],
          unit,
          timestamp: dayjs().toISOString(),
          sourceType: SourceType.HEALTHKIT,
          samples: values.map<QuantitySamplesUpsertValue>((sample) => ({
            value: sample.unit === 'm' ? sample.quantity * 1000 : sample.quantity,
            appleHealthKitUUID: sample.uuid,
            startDate: dayjs(sample.startDate).toISOString(),
            endDate: dayjs(sample.endDate).toISOString(),
          })),
        });

        if (response.data) {
          console.log('DATA', response.data);
        }

        if (response.error) {
          console.error(response.error);
        }
      }));
    }
  }
};

export const syncAllMetrics = async (upsertSamples: UpsertSamplesFn, upsertWorkoutSamples: UpsertWorkoutSamplesFn) => {
  const permissions = await Healthkit.isHealthDataAvailable() && await Healthkit.canAccessProtectedData() && await Healthkit.getRequestStatusForAuthorization(METRICS_TO_READ);
  let shouldSync = permissions === HKAuthorizationRequestStatus.unnecessary;
  if (permissions === HKAuthorizationRequestStatus.shouldRequest && AppState.currentState === 'active') {
    await Healthkit.requestAuthorization(METRICS_TO_READ);
    shouldSync = true;
  }

  if (shouldSync) {
    await Promise.all(METRICS_TO_READ.map(syncMetric(upsertSamples, upsertWorkoutSamples)));
  }
};

const useTriggerSync = () => {
  const [, upsertSamples] = useUpsertSamplesMutation();
  const [, upsertWorkoutSamples] = useUpsertWorkoutsMutation();

  const sync = useCallback(async () => {
    await syncAllMetrics(upsertSamples, upsertWorkoutSamples);
  }, [upsertSamples, upsertWorkoutSamples]);

  return sync;
};

export default useTriggerSync;

import React from 'react';
import { Text, Pressable, StyleSheet } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';

import { useTheme } from '../constants/Theme';

const styles = StyleSheet.create({
  primary: {
    flexDirection: 'row',
    width: '100%',
    // height: 56,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    flexWrap: 'wrap',
    marginBottom: 16,
    borderRadius: 8,
  },
  primaryText: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '400',
    flex: 1,
    fontSize: 20,
  },
  icon: {
    flexDirection: 'row',
    width: 'auto',
    // height: 56,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    flexWrap: 'wrap',
    marginBottom: 16,
    borderRadius: 8,
  },
  secondary: {
    width: '100%',
    height: 56,
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    flexWrap: 'wrap',
  },
  secondaryText: {
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  link: {
    width: '100%',
    height: 56,
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    flexWrap: 'wrap',
  },
  linkText: {
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

const Button = (props: any) => {
  const theme = useTheme();
  const {
    onPress, title, variant, iconSize, iconColor, icon,
  } = props;
  if (variant === 'primary') {
    return (
      <Pressable style={[styles.primary, { backgroundColor: theme.colors.primary }]} onPress={onPress}>
        {/* Ersätt med denna sen <StyledText size='huge'>Soon to be fit & healthy with friends</StyledText> */}
        <Text style={[styles.primaryText, { color: theme.colors.primaryText }]}>{title}</Text>
      </Pressable>
    );
  }
  if (variant === 'secondary') {
    return (
      <Pressable style={[styles.secondary, { backgroundColor: theme.colors.secondary }]} onPress={onPress}>
        <Text style={[styles.secondaryText, { color: theme.colors.secondaryText }]}>{title}</Text>
      </Pressable>
    );
  }
  if (variant === 'link') {
    return (
      <Pressable style={[styles.link, { backgroundColor: theme.colors.link }]} onPress={onPress}>
        <Text style={[styles.linkText, { color: theme.colors.linkText }]}>{title}</Text>
      </Pressable>
    );
  }
  if (variant === 'icon') {
    return (
      <Pressable style={[styles.icon, { backgroundColor: theme.colors.iconButtonBackground }]} onPress={onPress}>
        <AntDesign name={icon} size={iconSize || 20} color={theme.colors.iconButtonColor} />
      </Pressable>
    );
  }
  return (
    <Pressable style={[styles.primary, { backgroundColor: theme.colors.primary }]} onPress={onPress}>
      <Text style={[styles.primaryText, { color: theme.colors.primaryText }]}>{title}</Text>
    </Pressable>
  );
};

export default Button;

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Pressable, StyleSheet } from 'react-native';

import CompetitionsScreen from '../screens/CompetitionsScreen';
import {
  CompetitionsStackParamList, RootStackParamList,
} from '../types';
import { useTheme } from '../constants/Theme';

const CompetitionsStack = createNativeStackNavigator<CompetitionsStackParamList>();

const styles = StyleSheet.create({
  buttonCreate: {
    flexDirection: 'row',
    // height: 56,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    flexWrap: 'wrap',
    borderRadius: 100,
    marginRight: 16,
  },
});

const CompetitionsTabStack = () => {
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  return (
    <CompetitionsStack.Navigator screenOptions={{
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => (
        <Pressable
          onPress={() => navigation.navigate('NewCompetitionScreen')}
          style={[styles.buttonCreate, { backgroundColor: theme.colors.buttonCreateBackground }]}
        >
          <AntDesign name='plus' size={24} color={theme.colors.buttonCreateIcon} />
        </Pressable>
      ),
    }}
    >
      <CompetitionsStack.Screen
        name='Competitions'
        component={CompetitionsScreen}
        options={{
          title: 'Competitions',
          headerStyle: {
            backgroundColor: theme.colors.headerBackground,
          },
          headerShadowVisible: false,
          headerTransparent: false,
          headerTintColor: theme.colors.headerIconColor,
          headerTitleStyle: {
            color: theme.colors.headerColor,
          },
        }}
      />
    </CompetitionsStack.Navigator>
  );
};

export default CompetitionsTabStack;

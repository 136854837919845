import React from 'react';
import {
  TextInput as NativeTextInput, TextInputProps as NativeTextInputProps,
  Text, Pressable, StyleSheet,
} from 'react-native';
import { useBoolean } from '@kingstinct/react';

import { useTheme } from '../constants/Theme';

const styles = StyleSheet.create({
  input: {
    borderStyle: 'solid',
    width: '100%',
    height: 56,
    textAlign: 'left',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    marginBottom: 16,
    flexWrap: 'wrap',
    borderRadius: 8,
    outlineStyle: 'none',
    fontSize: 16,
  },
  focusedInput: {

  },
});
// eslint-disable-next-line react/display-name
export const TextInput = React.forwardRef<NativeTextInput, NativeTextInputProps>(({
  ...props
}, ref) => {
  const theme = useTheme();
  const [isFocused, setFocused, setBlur] = useBoolean(false);
  return (
    <NativeTextInput
      onFocus={setFocused}
      onBlur={setBlur}
      placeholderTextColor={theme.colors.inputPlaceholderColor}
      style={[
        styles.input, isFocused ? {
          backgroundColor: theme.colors.inputBackgroundFocus,
          borderColor: theme.colors.inputBorderFocus,
          color: theme.colors.inputColorFocus,
        } : {
          backgroundColor: theme.colors.inputBackground,
          borderColor: theme.colors.inputBorderColor,
          color: theme.colors.inputColor,
        }]}
      ref={ref}
      {...props}
    />
  );
});

export default TextInput;

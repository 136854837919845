import dayjs from 'dayjs';
import React, { useContext, useCallback } from 'react';
import { Switch } from 'react-native-paper';
import AntDesign from '@expo/vector-icons/AntDesign';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { Platform } from 'react-native';

import { useTheme } from '../constants/Theme';
import { AuthContext } from '../contexts/AuthContext';
import { useMetricsQuery, useAcceptInviteMutation } from '../clients/operations.generated';
import { Text, View } from '../components/Themed';
import { ProfileScreenProps } from '../types';
import useTriggerSync from '../hooks/useTriggerSync';
import Container from '../components/Container';
import Card from '../components/Card';
import Group from '../components/Group';
import {
  StyledText, StyledTitle,
} from '../components/Typo';
import requestHealthkitAccessIfNeeded from '../utils/requestHealthkitAccessIfNeeded';
import { useToggleBackgroundSync } from '../utils/syncInBackground';
import Button from '../components/Button';

const TabProfileScreen: React.FC<ProfileScreenProps<'Profile'>> = ({ route: { params } }) => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const [, acceptInvite] = useAcceptInviteMutation();
  const sync = useTriggerSync();
  const [{ data }, refetch] = useMetricsQuery({
    variables: { from: dayjs().subtract(1, 'week').startOf('day').toISOString() },
    requestPolicy: 'cache-and-network',
  });

  const triggerSync = useCallback(async () => {
    await sync();
    refetch();
  }, [sync, refetch]);
  const [isSyncEnabled, toggleSync] = useToggleBackgroundSync();
  const theme = useTheme();
  return (
    <Container>
      <Group style={{ flexGrow: 1 }}>
        <Card style={{ flexGrow: 1 }}>
          <Group style={{ gap: 8, alignItems: 'center' }}>
            <Container style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 0 }}>
              <Group style={{ alignItems: 'center', justifyContent: 'center', gap: 16 }}>
                <AntDesign name='Trophy' size={64} color={theme.colors.icon} />
                <StyledTitle size='md'>Won 3 competitions</StyledTitle>
              </Group>
              { data?.me ? (
                <Group style={{ alignItems: 'center', justifyContent: 'center', gap: 16 }}>
                  <MaterialCommunityIcons name='shoe-print' size={64} color={theme.colors.icon} />
                  <StyledTitle size='md'>{ `${data.me.stepCount.value} steps` }</StyledTitle>
                </Group>
              ) : null }

              { data && data.me.displayName !== data.me.email ? <StyledText>{ data.me.displayName }</StyledText> : null }
            </Container>
            <StyledText size='md'>{ data?.me.email }</StyledText>

            { params?.inviteToken ? <Button title='Accept Invite' onPress={() => acceptInvite({ inviteToken: params!.inviteToken! })} /> : null}

            {/* <View lightColor='#eee' darkColor='rgba(255,255,255,0.1)' /> */}
            <StyledText style={{ color: theme.colors.muted }}>{ isLoggedIn ? 'Logged in' : 'Not logged in' }</StyledText>
          </Group>
        </Card>
      </Group>

      <Group>
        <Button title='Logout' variant='primary' onPress={logout} />
        <Button title='Request Auth' variant='primary' onPress={requestHealthkitAccessIfNeeded} />
        <Button title='Sync' variant='primary' onPress={triggerSync} />
      </Group>
      {Platform.OS === 'ios' ? (
        <View>
          <Text>Background sync enabled</Text>
          <Switch value={isSyncEnabled} onChange={() => toggleSync()} />
        </View>
      ) : null }
    </Container>
  );
};

export default TabProfileScreen;
